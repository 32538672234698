import store from '@/store';
import storage from '@/plugins/storage';

export default (to, from, next) => {
  if (!to.params?.force && store.getters['auth/isLogged']) {
    next({ name: 'home' });
  } else {
    storage.set('NEW_APP_BY_DEFAULT', false);
    next();
  }
};
