import store from '@/store';
import storage from '@/plugins/storage';

export default async (to, from, next) => {
  const { isNewApplicationEnabled } = store.state.user.location;
  const isViewAs = store.state.auth.viewAs;
  const isTermsAndDistributionRulesAccepted = store.getters['user/isTermsAndDistributionRulesAccepted'];

  if (
    process.env.VUE_APP_NEW_BRIDGE
    && isNewApplicationEnabled
    && isTermsAndDistributionRulesAccepted
  ) {
    if (!isViewAs) {
      storage.set('NEW_APP_BY_DEFAULT', true);
    }
    next({ name: 'newapp' });
  } else {
    next();
  }
};
