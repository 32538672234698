<template>
  <span></span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {

  props: {
    expiresInSeconds: Number,
    accessToken: String,
    refreshToken: String,
    returnPath: String,
    redirectPath: String,
  },

  computed: {
    tokenData() {
      const {
        expiresInSeconds,
        accessToken,
        refreshToken,
        returnPath,
        redirectPath,
      } = this;

      return {
        expiresInSeconds,
        accessToken,
        refreshToken,
        returnPath,
        redirectPath,
      };
    },
    isValid() {
      const {
        returnPath,
        redirectPath,
        ...tokenData
      } = this.tokenData;

      return Object.values(tokenData).every((v) => v);
    },
  },

  async mounted() {
    if (this.isValid) {
      try {
        this.resetStore('auth');
        this.setToken(this.tokenData);

        this.$router.replace({
          name: this.tokenData?.redirectPath || 'home',
        });

        return;
      } catch (e) {
        //
      }
    }

    this.$router.push({
      name: this.tokenData?.redirectPath || 'login',
    });
  },

  methods: {
    ...mapMutations('auth', [
      'setToken',
    ]),
    ...mapActions([
      'resetStore',
    ]),
  },
};
</script>
