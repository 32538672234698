/**
 * Convert file to base64.
 *
 * @param  {File} file
 * @return {base64}
 */
export default (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e) => {
      return resolve(reader.result.split(',')[1]);
    };

    reader.onerror = (error) => {
      return reject(error);
    };
  });
};
