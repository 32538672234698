import Vue from 'vue';
import store from '@/store';

export default async (to, from, next) => {
  if (
    store.getters['auth/isLogged']
    && !store.state.user.image
  ) {
    try {
      await store.dispatch('user/loadImage');
    } catch (e) {
      if (e?.response?.status >= 500) {
        Vue.prototype.$notify({
          message: 'Failed to complete the request',
          type: 'warning',
        });
      }
    }
  }

  next();
};
