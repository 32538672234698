import semver from 'semver';
import { APP_VERSION, APP_PLATFORM } from '@/config/app';

export default (needVersion) => {
  if (!needVersion || APP_PLATFORM === 'Web') {
    return false;
  }

  return semver.lt(APP_VERSION, needVersion);
};
