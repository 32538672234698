import api from '@/api';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    sublocations: [],
    selectedSublocation: null,

    sublocationsStaffs: [],
    sublocationsStaffsLoading: false,
  }),

  getters: {
    commonSublocations({ sublocations }) {
      return sublocations.filter((sublocation) => sublocation.isMasterLocation || sublocation.tipsType === 'COMMON');
    },
    selectedAllSublocations({ selectedSublocation }) {
      return selectedSublocation.isMasterLocation;
    },
  },

  actions: {
    async loadSublocations({ state, commit }, { location }) {
      try {
        const { data } = await api.locations.getSublocations({ locationId: location.id });
        commit('set', { sublocations: [{ ...location, name: 'All sub-locations' }, ...data] });
        commit('set', { selectedSublocation: state.sublocations[0] });
      } catch (e) {
        console.error(e);
      }
    },
    async loadStaffs({ state, commit }, sublocation) {
      try {
        const { data } = await api.locations.getStaffs({ locationId: sublocation.id });

        const sublocationsStaffsIndex = state.sublocationsStaffs.findIndex((location) => location.id === sublocation.id);
        if (sublocationsStaffsIndex > -1) {
          const sublocationsStaffs = [...state.sublocationsStaffs];
          sublocationsStaffs.splice(sublocationsStaffsIndex, 1, { ...sublocation, staffs: data });
          commit('set', { sublocationsStaffs });
        } else {
          commit('set', {
            sublocationsStaffs: [
              ...state.sublocationsStaffs,
              { ...sublocation, staffs: data },
            ],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadSublocationsStaffs({ state, commit, dispatch }) {
      commit('set', {
        sublocationsStaffsLoading: true,
        sublocationsStaffs: [],
      });

      try {
        state.sublocations
          .filter((sublocation) => !(sublocation).isMasterLocation)
          .forEach((sublocation) => {
            dispatch('loadStaffs', sublocation);
          });
      } catch (e) {
        console.log(e);
      } finally {
        commit('set', { sublocationsStaffsLoading: false });
      }
    },
    setSelectedSublocation({ state, commit }, sublocationId) {
      const sublocationById = state.sublocations.find(
        (sublocation) => sublocation.id === sublocationId,
      );
      commit('setSelectedSublocation', sublocationById || state.sublocations[0]);
    },
    resetSelectedSublocation({ state, commit }) {
      commit('setSelectedSublocation', state.sublocations[0] || null);
    },
  },

  mutations: {
    setSelectedSublocation(state, sublocation) {
      state.selectedSublocation = sublocation;
    },
  },
});
