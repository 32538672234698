import { DateTime } from 'luxon';
import parseDate from '@/utils/parseDate';

/**
 * Time formatting.
 *
 * @example
 *
 * 09:30 AM
 *
 * @param  {*} value
 * @return {string}
 */
export default (value, zone) => {
  let parsedToJSDate = DateTime.fromJSDate(parseDate(value));

  if (zone) {
    parsedToJSDate = parsedToJSDate.setZone(zone);
  }

  return parsedToJSDate.toLocaleString(DateTime.TIME_SIMPLE);
};
