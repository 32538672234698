import formatDate from '@/filters/date';

/**
 * Concat period dates.
 *
 * @param  {string|Date} startDate
 * @param  {string|Date} endDate
 * @return {string}
 */
export default function (startDate, endDate) {
  return [startDate, endDate]
    .filter((v) => v)
    .map((v) => formatDate(v))
    .join(' - ');
}
