/* eslint-disable no-use-before-define */

import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import qs from 'qs';
import routes from './routes';

Vue.use(VueRouter);

// The middleware for every page of the application.
const globalMiddleware = [];

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context('@/middleware', false, /.*\.js$/),
);

const router = createRouter();

export default router;

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const r = new VueRouter({
    scrollBehavior,
    mode: 'history',
    linkActiveClass: 'active',
    routes,

    // @see {@link https://github.com/vuejs/vue-router/issues/1268}
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);

      return result ? `?${result}` : '';
    },
  });

  r.beforeEach(beforeEach);
  r.afterEach(afterEach);

  return r;
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) { // eslint-disable-line consistent-return
  // Get the matched components and resolve them.
  const components = (await resolveComponents(
    router.getMatchedComponents({ ...to }),
    // eslint-disable-next-line no-underscore-dangle
  )).map((component) => (component.__esModule ? component.default : component));

  // Get the middleware for all the matched components.
  const middlewares = [
    ...globalMiddleware,
    ...getRouteMiddlewares(to),
    ...getComponentsMiddlewares(components),
  ];

  if (middlewares.length === 0) {
    return next();
  }

  // Start the loading bar.
  if (components[components.length - 1].loading !== false) {
    NProgress.start();
  }

  // Call each middleware.
  callMiddleware(middlewares, to, from, (...args) => {
    next(...args);
  });
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach(to, from, next) {
  await router.app.$nextTick();

  NProgress.done();
}

/**
 * Call each middleware.
 *
 * @param {Array} middlewares
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware(middlewares, to, from, next) {
  const stack = middlewares.reverse();

  const nextRun = (...args) => { // eslint-disable-line consistent-return
    // Stop if "nextRun" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      if (args.length > 0) {
        NProgress.done();
      }

      return next(...args);
    }

    const nextMiddleware = stack.pop();

    if (typeof nextMiddleware === 'function') {
      nextMiddleware(to, from, nextRun);
    } else if (routeMiddleware[nextMiddleware]) {
      routeMiddleware[nextMiddleware](to, from, nextRun);
    } else {
      throw Error(`Undefined middleware [${nextMiddleware}]`);
    }
  };

  nextRun();
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
  return Promise.all(components.map(async (component) => {
    if (typeof component !== 'function') {
      return component;
    }

    const response = await component();

    return response;
  }));
}

/**
 * Return components middleware array.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getComponentsMiddlewares(components) {
  const middlewares = [];

  components.forEach((component) => {
    if (!component.middleware) {
      return;
    }

    if (Array.isArray(component.middleware)) {
      middlewares.push(...component.middleware);
    } else {
      middlewares.push(component.middleware);
    }
  });

  return middlewares;
}

/**
 * Return route middleware array.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getRouteMiddlewares(to) {
  const middlewares = [];

  to.matched.forEach((route) => {
    if (!route.meta.middleware) {
      return;
    }

    middlewares.push(
      ...Array.isArray(route.meta.middleware)
        ? route.meta.middleware
        : [route.meta.middleware],
    );
  });

  return middlewares;
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    return { selector: to.hash };
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1);

  if (component && component.scrollToTop === false) {
    return {};
  }

  return { x: 0, y: 0 };
}

/**
 * Return middleware list.
 *
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
  return requireContext.keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce((guards, [name, guard]) => ({ ...guards, [name]: guard.default }), {});
}
