import axios from 'axios';
import api from '@/api';
import store from '@/store';
import router from '@/router';

/**
 * Add authorization header to request config.
 *
 * @param {Object} config
 * @returns {Object}
 */
function withAuth(config) {
  const { accessToken } = store.state.auth;
  const { auth = true } = config;

  if (accessToken && auth) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
}

/**
 * Error is jwt exception.
 *
 * @param {Error} e
 * @returns {boolean}
 */
function isDead(e) {
  return e.response?.status === 403 && e.response?.data?.errorType === 'ExpiredJwtException';
}

/**
 * Refresh token or redirect to login page.
 *
 * @param  {Function} next
 * @return {Promise}
 */
function refreshToken(next) {
  return store.dispatch('auth/refreshToken', next)
    .catch((e) => {
      if (isDead(e)) {
        return router.push({
          name: 'login',
          params: {
            force: true,
          },
        });
      }

      throw e;
    });
}

/**
 * Specified request config is refreshable.
 *
 * @param {Object} request
 * @returns {boolean}
 */
function isRefreshable(request) {
  if (request.auth === false) {
    return false;
  }

  // refresh or logout request.
  if (request.data?.refreshToken) {
    return false;
  }

  if (store.state.auth.refreshState) {
    return true;
  }

  return store.getters['auth/isRefreshable'] && store.state.auth.expiresIn < +Date.now();
}

export default function (Vue) {
  const { $notify } = Vue.prototype;

  /**
   * Attach authorization header.
   */
  api.instance.easytip.interceptors.request.use((request) => {
    const next = () => withAuth(request);

    if (isRefreshable(request)) {
      return refreshToken(next);
    }

    return next();
  });

  /**
   * Handling api response.
   */
  api.instance.easytip.interceptors.response.use(undefined, (error) => {
    if (axios.isCancel(error)) {
      return false;
    }

    const {
      config,
      response,
    } = error;

    // Browser error.
    if (!response) {
      throw error;
      // return window.location.reload();
    }

    // Authorization needed.
    if (isDead(error)) {
      return refreshToken(() => api.instance.easytip.request(withAuth(config)));
    }

    // Internal server error.
    if (response.status >= 500) {
      // $notify({
      //   title: 'Oops!',
      //   message: 'Something wrong. Try again later..',
      //   type: 'danger',
      // });
    }

    // Access denied.
    if (response.status === 403) {
      // $notify({
      //   title: 'Access denied',
      //   message: 'You don\'t have permission for this.',
      //   type: 'danger',
      // });
    }

    throw error;
  });
}
