/**
 * Convert canvas element to Blob.
 *
 * @param {HTMLElement} canvas
 * @return {Promise<Blob>}
 */
export default (canvas, type = 'image/jpeg') => { // eslint-disable-line arrow-body-style
  return new Promise((resolve, reject) => {
    if (typeof canvas.toBlob !== 'undefined') {
      canvas.toBlob(resolve, type, 0.75);
    } else if (typeof canvas.msToBlob !== 'undefined') {
      resolve(canvas.msToBlob());
    } else {
      reject();
    }
  });
};
