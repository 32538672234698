import storage from '@/plugins/storage';

export default () => {
  if (!window.cordova) {
    return false;
  }

  let welcomeScreens = storage.get('welcomeScreens');

  if (welcomeScreens === null) {
    welcomeScreens = true;

    storage.set('welcomeScreens', welcomeScreens);
  }

  return welcomeScreens;
};
