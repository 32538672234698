<template>
  <app-onboarding v-if="!landing">
    <validation-observer tag="form" v-slot="{ handleSubmit }">
      <ui-card type="login">
        <template #header>
          <h4 class="card-title text-center">Login</h4>
        </template>

        <div class="row mt-4">
          <div class="col-12">
            <ui-input
              v-model="username"
              label="Login or email"
              :class="error ? 'has-danger' : false"
              autocapitalize="off"
              spellcheck="false"
              label-overlay
            ></ui-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ui-password
              v-model="password"
              :error="error"
              label="Password"
              label-overlay
            />
          </div>
        </div>

        <router-link
          v-if="!isMobile"
          :to="{ name: 'recovery' }"
          class="text-primary"
        >
          Forgot password or username?
        </router-link>

        <div
          v-else
          class="text-primary cursor-pointer"
          @click="openRecovery"
        >
          Forgot password or username?
        </div>

        <template #footer>
          <ui-button
            native-type="submit"
            type="primary"
            block
            class="mb-3"
            :is-loading="loading"
            :disabled="loading"
            @click.prevent="handleSubmit(submit)">
            Login
          </ui-button>
        </template>
      </ui-card>
    </validation-observer>
    <vue-bottom-sheet ref="recoverySheet">
      <recovery
        title="Enter received code"
        onboarding
        otp-input
        no-padding
        resend-code
        no-box-shadow
        @confirm="confirm"
        @goLogin="closeRecovery"
      />
    </vue-bottom-sheet>
  </app-onboarding>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import AppOnboarding from '@/components/AppOnboarding.vue';
import Recovery from '@/components/Recovery.vue';

const {
  NODE_ENV,
  VUE_APP_DASHBOARD_LOGIN,
  VUE_APP_LANDING_URL,
} = process.env;

export default {

  components: {
    Recovery,
    AppOnboarding,
  },

  data: () => ({
    landing: !VUE_APP_DASHBOARD_LOGIN && NODE_ENV === 'production',
    loading: false,
    username: '',
    password: '',
    error: '',
  }),

  computed: {
    ...mapState({
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isUserHasAccess: 'sidebar/access',
    }),
    isAdminOrViewAsMode() {
      return this.viewAsMode !== null
        || this.isAdmin;
    },
    type() {
      if (/@/.test(this.username)) {
        return 'email';
      }

      return '';
    },
    isMobile() {
      return window.innerWidth <= 760;
    },
  },

  mounted() {
    if (this.landing) {
      // window.location.href = `${VUE_APP_LANDING_URL}/login`;
    }
  },

  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    openRecovery() {
      this.$refs.recoverySheet.open();
    },
    closeRecovery() {
      this.$refs.recoverySheet.close();
    },
    confirm() {
      this.closeRecovery();
      this.$router.push({ name: 'recovery-success' });
    },
    async submit() {
      if (this.loading) {
        return;
      }

      const {
        type,
        username,
        password,
      } = this;

      const attribute = type || 'username';

      this.loading = true;
      this.error = '';

      try {
        await this.login({
          type,
          [attribute]: username,
          password,
        });

        if (!this.isAdminOrViewAsMode) {
          this.$amplitude.event('USER_LOGIN_SUCCESS', {
            userRoles: this.userRoles,
          });
        }

        const path = this.$route.query?.path;
        const name = this.$route.query?.name;

        if (this.isUserHasAccess[name] && path) {
          this.$router.push({ path });
        } else {
          this.$router.push({ name: 'home' });
        }
      } catch (e) {
        if (!this.isAdminOrViewAsMode) {
          this.$amplitude.event('USER_LOGIN_ERROR', {
            userRoles: this.userRoles,
          });
        }

        if (e?.response?.status >= 500) {
          this.$showServerError(e);
        } else {
          this.error = e?.response?.data?.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
