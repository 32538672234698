import { DateTime } from 'luxon';
import parseDate from './parseDate';

/**
 * Age of date birth.
 *
 * @param {*} value
 * @returns {number}
 */
function age(value) {
  value = parseDate(value);

  if (!value) {
    return 0;
  }

  return Math.floor(DateTime.local().diff(DateTime.fromJSDate(value), 'years').years);
}

export default age;
