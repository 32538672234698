import storage from '@/plugins/storage';

export default () => {
  if (!window.cordova) {
    return false;
  }

  const welcomeScreens = storage.set('welcomeScreens', false);

  return welcomeScreens;
};
