export default (fn) => ({

  data() {
    return {
      ...fn(),
    };
  },

  methods: {
    reset() {
      Object.entries(fn()).forEach(([key, value]) => {
        this[key] = value;
      });
    },
  },
});
