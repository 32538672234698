/* eslint-disable import/first */

import Vue from 'vue';

// Libraries.
import './pollyfills';
import './assets/sass/paper-dashboard.scss';
import './assets/sass/demo.scss';
import './assets/sass/theme/index.scss';
import 'vue-notifyjs/themes/default.css';
import './validation';

// Localization.
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

// Luxon dates.
import { Settings } from 'luxon';

Settings.defaultLocale = 'en-US';

// Plugins.
import VueRouterPrefetch from 'vue-router-prefetch';
import VueNotify from 'vue-notifyjs';
import VueTelInput from 'vue-tel-input';
import VueTheMask from 'vue-the-mask';
import VueClipboard from 'vue-clipboard2';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import VueObserveVisibility from 'vue-observe-visibility';
import AuthPlugin from './plugins/auth';
import NotifyPlugin from './plugins/notify';
import i18n from './plugins/i18n';
import i18nLower from './plugins/i18n-lower';
import AlertPlugin from './plugins/alert';
import ScrollPlugin from './plugins/scroll';
import amplitude from './plugins/amplitude';
import staffTypeName from './plugins/staffTypeName';
import ApiSwitchPlugin from './plugins/api-switch';
import CountriesPlugin from './plugins/countries';
import './plugins/sentry';

Vue.use(VueRouterPrefetch);
Vue.use(VueNotify);
Vue.use(VueTelInput);
Vue.use(VueTheMask);
Vue.use(AuthPlugin);
Vue.use(NotifyPlugin);
Vue.use(AlertPlugin);
Vue.use(ScrollPlugin);
Vue.use(VueClipboard);
Vue.use(VueBottomSheet);
Vue.use(VueObserveVisibility);
Vue.use(amplitude);
Vue.use(staffTypeName);
Vue.use(i18nLower);
Vue.use(ApiSwitchPlugin);
Vue.use(CountriesPlugin);

// Directives.
import './directives';

// Components.
import './components';

// Filters.
import './filters';

// Create application.
import App from './components/App.vue';
import store from './store';
import router from './router';
// import './plugins/service-worker';

// import './mocks';

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  i18n,
});
