import store from '@/store';
import { PAYMENT_SYSTEMS_REDIRECT_REPORTING } from '@/config/paymentSystems';

export default async (to, from, next) => {
  if (to.name === 'support' && from.name === 'token') {
    next();
    return;
  }

  if (to.name === 'home') {
    const { paymentSystem } = store.state.user.profile;
    const hasAccess = store.getters['sidebar/access'];

    if (hasAccess.reporting && PAYMENT_SYSTEMS_REDIRECT_REPORTING.includes(paymentSystem)) {
      next({ name: 'reporting' });
      return;
    }

    const nextUrl = from.query?.path;
    const urls = ['/login', '/'];

    if (nextUrl && !urls.includes(nextUrl)) {
      next({ path: nextUrl });
      return;
    }
  }

  next();
};
