/* eslint-disable import/no-cycle */

import { pick, pickBy } from 'lodash';
import api from '@/api';
import { NOBODY } from '@/config/staffTypes';
import { APP_PLATFORM } from '@/config/app';
import { CHECKOUT_PAYMENT_SYSTEM_LIST, PAYMENT_SYSTEM } from '@/config/paymentSystems';
import needUpdate from '@/utils/needUpdate';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    profile: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      wish: null,
      dateOfBirth: null,
      staffType: null,
      staffId: null,
      staffs: [],
      taxReportingType: null,
      nickName: null,
      distributionSchemes: null,
      login: null,
      paymentSystem: null,
      isSharedCard: null,
      isAllowedSharedCard: null,
      pendingPayoutAlert: false,
      isRequiredBankAccountInformation: false,
      isRequiredAdditionalInfo: false,
      isNeedNymOnboarding: false,
    },
    location: {
      id: 0,
      address: null,
      city: null,
      country: null,
      name: null,
      type: null,
      groupName: null,
      workplaceFee: 0,
      currencyCode: null,
      taxReportingStatus: null,
      paymentPage: null,
      showReviewsModule: null,
      showSetDistributionModule: null,
      activePromo: null,
      paymentSystem: null,
      taxReportingType: null,
      isAllowedSharedCard: null,
      isOnlineMenuEnabled: null,
      isOnboardingEnabled: null,
      isNymcardEnabled: null,
      isMasterLocation: null,
      isNewApplicationEnabled: null,
      isEnableSubscriptionPlans: null,
    },
    locations: [],
    infoLoaded: false,
    isTermAccepted: false,
    isDistributionRulesAccepted: false,
    isAcceptedNymTerms: false,
    showManagePayout: false,
    showManageVenuePayout: false,
    rating: 0,
    image: null,
    changeLocationLoading: false,
    showRequestIdModal: false,
  }),

  getters: {
    fio({ profile }) {
      return [
        profile.firstName,
        profile.lastName,
      ].filter((v) => v).join(' ');
    },
    isProfileFilled({ profile }) {
      const requiredFields = [
        'email',
        'firstName',
        'lastName',
      ];
      if (profile.staffType && profile.staffType !== NOBODY) {
        requiredFields.push('dateOfBirth');
      }
      const attributes = pick(profile, requiredFields);

      return Object.values(attributes).every((v) => v);
    },
    needRequestId({ profile, isTermAccepted }, getters) {
      return profile.isRequiredAdditionalInfo && getters.isProfileFilled && isTermAccepted;
    },
    isPersonal(state) {
      return state.location.tipsType === 'PERSONAL';
    },
    isCommon(state) {
      return state.location.tipsType === 'COMMON'
        || state.location.tipsType === 'COMMON_PRIORITY';
    },
    isUk(state) {
      return state.location.country === 'gb';
    },
    isGermany(state) {
      return state.location.country === 'de';
    },
    isUAE(state) {
      return state.location.country === 'ae';
    },
    isSwitzerland(state) {
      return state.location.country === 'ch';
    },
    isDefaultLocationExist(state) {
      return state.locations.some((item) => item.isDefault);
    },
    hasLocationFee(state) {
      return !!state.location.workplaceFee;
    },
    isSelfReportingTypeLocation(state) {
      return state.location.taxReportingType === 'SELF_REPORTING';
    },
    isEmployerReportingTypeLocation(state) {
      return state.location.taxReportingType === 'EMPLOYER';
    },
    isMixReportingTypeLocation(state) {
      return state.location.taxReportingType === 'MIX_REPORTING';
    },
    isSelfReportingTypeProfile(state) {
      return state.profile.taxReportingType === 'SELF_REPORTING';
    },
    isEmployerReportingTypeProfile(state) {
      return state.profile.taxReportingType === 'EMPLOYER';
    },
    defaultLocationRequired(state, getters, rootState, rootGetters) {
      if (rootGetters['auth/isAdminOrSales']) {
        return false;
      }

      return !getters.isDefaultLocationExist;
    },
    showReviewsModule(state) {
      return state.location.typeOfReview !== 'DISABLE_REVIEWS';
    },
    isProfileCheckout(state) {
      return CHECKOUT_PAYMENT_SYSTEM_LIST.includes(state.profile.paymentSystem);
    },
    isProfileStripe(state) {
      return state.profile.paymentSystem === PAYMENT_SYSTEM.STRIPE;
    },
    isProfilePayerMax(state) {
      return state.profile.paymentSystem === PAYMENT_SYSTEM.PAYER_MAX;
    },
    isProfileRyft(state) {
      return state.profile.paymentSystem === PAYMENT_SYSTEM.RYFT;
    },
    isUndefinedReportingTypeLocation(state) {
      return !state.location.taxReportingType;
    },
    isLocationCheckout(state) {
      return CHECKOUT_PAYMENT_SYSTEM_LIST.includes(state.location.paymentSystem);
    },
    isCheckout(state, getters, rootState, rootGetters) {
      if (rootGetters['auth/isOwnerAndStaff']) {
        return getters.isProfileCheckout
          || getters.isLocationCheckout;
      }

      if (rootGetters['auth/isOnlyOwner']) {
        return getters.isLocationCheckout;
      }

      if (rootGetters['auth/isOnlyStaff']) {
        return getters.isProfileCheckout;
      }

      return false;
    },
    isSharedCard(state) {
      return state.profile.isSharedCard;
    },
    isAllowedSharedCard(state) {
      return state.profile.isAllowedSharedCard;
    },
    isCommonStaff(state, getters, rootState, rootGetters) {
      return getters.isCommon && rootGetters['auth/isStaff'];
    },
    isOnlineMenuEnabled(state) {
      return state.location.isOnlineMenuEnabled;
    },
    isTermsAndDistributionRulesAccepted(state) {
      const { isDistributionRulesAccepted, isTermAccepted, isAcceptedNymTerms } = state;
      return isTermAccepted && isDistributionRulesAccepted && isAcceptedNymTerms;
    },
    isTemporaryPasswordStatus(state) {
      return state.profile.status === 'INITIALIZED';
    },
    isOnboardingEnabled(state) {
      return state.location?.isOnboardingEnabled;
    },
    isNymcardEnabled(state) {
      return state.location?.isNymcardEnabled;
    },
    needChangeTemporaryPassword(state, getters) {
      return getters.isTemporaryPasswordStatus
        && getters.isTermsAndDistributionRulesAccepted
        && getters.needOnboarding;
    },
    needUpdateApplication(state) {
      return needUpdate(state.profile.minAppVersion);
    },
    needOnboarding(state, getters, rootState, rootGetters) {
      const { viewAs } = rootState.auth;

      return getters.isTermsAndDistributionRulesAccepted
        && !getters.isProfileFilled
        && !viewAs
        && getters.isOnboardingEnabled;
    },
    needNymOnboarding(state, getters, rootState) {
      const { viewAs } = rootState.auth;

      return getters.isTermsAndDistributionRulesAccepted
        && !viewAs
        && state.profile.isNeedNymOnboarding;
    },
    needRedirectFromTermsToPasswordChanging(state, getters) {
      return getters.isTemporaryPasswordStatus && getters.isOnboardingEnabled;
    },
    isMasterLocation(state) {
      return state.location.isMasterLocation;
    },
    enableNymCard(state) {
      return state.profile.enableNymCard;
    },
  },

  actions: {
    /**
     * User information.
     */
    async loadInfo({
      state,
      commit,
      dispatch,
      rootGetters,
    }) {
      commit('staffs/reset', null, { root: true });
      commit('groups/reset', null, { root: true });
      commit('sublocations/reset', null, { root: true });

      if (rootGetters['auth/isSalesPerson']) {
        await dispatch('loadSalesUser');
      } else {
        await dispatch('loadUser');
      }

      if (!rootGetters['auth/isAdminOrSales']) {
        await dispatch('loadLocationList');
      }

      if (
        (
          rootGetters['auth/isOwner']
          || rootGetters['auth/isAccountant']
          || rootGetters['auth/isManager']
          || rootGetters['auth/isDistributionMaster']
        ) && (!rootGetters['auth/isAdminOrSales'])
      ) {
        await dispatch('loadLocation');
        await dispatch('loadLocationActivePromo');
        await dispatch('staffs/loadItems', { locationId: state.location.id }, { root: true });

        if (state.location.paymentPage === 'GROUPS') {
          await dispatch('groups/loadItems', { locationId: state.location.id }, { root: true });
        }

        if (rootGetters['auth/isOwner'] && state.location.isMasterLocation) {
          await dispatch('sublocations/loadSublocations', { location: state.location }, { root: true });
        }
      }

      await dispatch('notifications/loadMessages', null, { root: true });

      await dispatch('loadCheckoutAndBalance');

      commit('set', { infoLoaded: true });
    },
    async loadUserStaffs({ state, commit }) {
      const { data } = await api.user.getUserStaffs({ staffId: state.profile.id });

      commit('set', {
        profile: {
          ...state.profile,
          staffs: data,
        },
      });
    },
    async loadCheckoutAndBalance({
      state,
      getters,
      dispatch,
      rootGetters,
    }) {
      if (
        rootGetters['auth/isPayoutViewer']
        && (getters.isProfileCheckout || getters.isLocationCheckout || getters.isProfilePayerMax)
      ) {
        await dispatch('payouts/initPayouts', null, { root: true });
      }
    },
    async loadUser({ commit, rootState, dispatch }) {
      const {
        data: {
          isTermAccepted = false,
          isDistributionRulesAccepted = false,
          isAcceptedNymTerms = false,
          workPlace = {},
          showManagePayout = false,
          showManageVenuePayout = false,
          ...profile
        },
      } = await api.user.getProfile();

      const defaults = await dispatch('getDefaults');
      const { viewAs } = rootState.auth;

      commit('set', {
        isTermAccepted: isTermAccepted || viewAs,
        isDistributionRulesAccepted: isDistributionRulesAccepted || viewAs,
        isAcceptedNymTerms: (profile.enableNymCard ? isAcceptedNymTerms : true) || viewAs,
        showManagePayout,
        showManageVenuePayout,
        profile: {
          ...defaults.profile,
          ...profile,
        },
        location: {
          ...defaults.location,
          ...workPlace,
        },
      });
    },
    async loadSalesUser({ commit, dispatch }) {
      const {
        data: {
          isTermAccepted = false,
          ...profile
        },
      } = await api.user.getSalesProfile();

      const defaults = await dispatch('getDefaults');

      commit('set', {
        isTermAccepted,
        profile: {
          ...defaults.profile,
          ...profile,
        },
      });
    },
    async loadLocationActivePromo({ state, commit }) {
      const { data } = await api.locations.getPromotions({
        locationId: state.location.id,
        status: 'ACTIVE',
      });

      if (data.length) {
        commit('set', {
          location: {
            activePromo: data[0],
          },
        });
      }
    },
    async loadLocation({ state, commit, dispatch }) {
      const { data } = await api.locations.getLocation({
        locationId: state.location.id,
      });

      const defaults = await dispatch('getDefaults');

      commit('set', {
        location: {
          ...defaults.location,
          ...pickBy(data),
        },
      });
    },
    async loadLocationList({ state, commit }) {
      const { data: locations } = await api.locations.getUserLocations({
        userId: state.profile.id,
      });

      await Promise.all(
        locations.map(async (location) => {
          try {
            const { data: image } = await api.locations.getImage({
              locationId: location.id,
            });

            if (image && image.size > 0) {
              location.image = URL.createObjectURL(image);
            }
          } catch (e) {
            // noop.
          }
        }),
      );

      locations.sort((x, y) => x.id - y.id);

      commit('set', {
        locations,
      });
    },
    async setLocation({ state, commit, dispatch }, { locationId }) {
      commit('set', { changeLocationLoading: true });
      await api.locations.setLocation({
        userId: state.profile.id,
        locationId,
      });

      commit('set', {
        location: {
          ...state.location,
          id: locationId,
        },
      });

      try {
        await dispatch('loadInfo');
      } finally {
        commit('set', { changeLocationLoading: false });
      }
      return dispatch('support/initialize', null, { root: true });
    },
    /**
     * Avatar image.
     */
    async loadImage({ commit, rootGetters }) {
      const { data } = await rootGetters.api.getImage();

      if (data && data.size > 0) {
        commit('set', { image: URL.createObjectURL(data) });
      }

      return data;
    },
    async uploadImage({ dispatch, rootGetters }, payload) {
      await rootGetters.api.uploadImage(payload);

      dispatch('loadImage');
    },
    /**
     * Password.
     */
    async updatePassword({ rootGetters }, payload) {
      const { data } = await rootGetters.api.updatePassword(payload);

      return data;
    },
    /**
     * Generated password.
     */
    async updateGeneratedPassword({ commit, rootGetters }, payload) {
      await rootGetters.api.updateGeneratedPassword(payload);

      commit('set', {
        profile: {
          status: 'ACTIVE',
        },
      });
    },
    /**
     * Phone.
     */
    async updatePhone({ rootGetters }, payload) {
      const { data } = await rootGetters.api.updatePhone(payload);

      return data;
    },
    async getPhoneToken({ rootGetters }, payload) {
      const { data } = await rootGetters.api.getPhoneToken(payload);

      return data;
    },
    async confirmPhone({ dispatch, rootGetters }, payload) {
      await rootGetters.api.confirmPhone(payload);

      dispatch('loadInfo');
    },
    /**
     * Terms and conditions.
     */
    async applyTerms({ state, commit }) {
      await api.user.applyTerms(state.profile);

      commit('set', {
        isTermAccepted: true,
      });
    },
    /**
     * Apply distributions rules.
     */
    async applyDistributions({ state, commit }) {
      await api.user.applyDistributions(state.profile);

      commit('set', {
        isDistributionRulesAccepted: true,
      });
    },
    /**
     * Apply Nym terms.
     */
    async applyNymTerms({ state, commit }) {
      await api.user.applyNymTerms(state.profile);

      commit('set', {
        isAcceptedNymTerms: true,
      });
    },
  },
  mutations: {
    setVisibilityRequestIdModal(state, isVisible) {
      state.showRequestIdModal = isVisible;
    },
  },
});
