import Vue from 'vue';
import { uniq, map } from 'lodash';
import api from '@/api';
import toBase64 from '@/utils/toBase64';
import fromBytesToKb from '@/utils/fromBytesToKb';
import fromBytesToMb from '@/utils/fromBytesToMb';

import factory from '../factory';

const parseFromSingleSectionList = (list) => {
  return list.map((file) => ({
    fileId: file.fileId,
    displayOrder: file.displayOrder,
    section: null,
  }));
};
const parseToSingleSectionList = (list) => {
  return list
    .map(({ section, fileSize, ...fileData }) => ({
      ...fileData,
      fileSize: fileSize > 1e6
        ? `${fromBytesToMb(fileSize)} Mb`
        : `${fromBytesToKb(fileSize)} Kb`,
      selected: false,
    }))
    .sort((a, b) => a.displayOrder - b.displayOrder);
};
const parseFromMultiSectionList = (list) => {
  const parsedList = [];

  list.forEach(({ name, order, files }) => {
    files.forEach(({ fileId, displayOrder }) => {
      parsedList.push({
        section: {
          name,
          order,
        },
        displayOrder,
        fileId,
      });
    });
  });

  return parsedList;
};
const parseToMultiSectionList = (list) => {
  const sortedSectionList = list.sort((a, b) => a.section.order - b.section.order);
  const uniqueSectionNameList = uniq(map(sortedSectionList, ({ section }) => section.name));

  return uniqueSectionNameList.map((sectionName, index) => {
    return {
      order: index,
      name: sectionName,
      files: list
        .filter(({ section }) => sectionName === section.name)
        .map(({ section, fileSize, ...fileData }) => ({
          ...fileData,
          fileSize: fileSize > 1e6
            ? `${fromBytesToMb(fileSize)} Mb`
            : `${fromBytesToKb(fileSize)} Kb`,
          selected: false,
        }))
        .sort((a, b) => a.displayOrder - b.displayOrder),
    };
  });
};

export default factory({

  namespaced: true,

  state: () => ({
    loading: {
      file: false,
    },
    sectionValidationKey: '',
    currentUploadSectionId: null,
    sectionsMode: false,
    singleSectionList: [],
    multiSectionList: [
      {
        order: 0,
        name: '',
        files: [],
      },
    ],
  }),

  getters: {
    getSingleSectionSelectedFileId(state) {
      return state.singleSectionList.findIndex(({ selected }) => selected);
    },
    getMultiSectionSelectedFileId(state) {
      return (sectionId) => {
        return state.multiSectionList[sectionId].files.findIndex(({ selected }) => selected);
      };
    },
  },

  mutations: {
    /**
     * Multi section mutations
    */
    addSection(state) {
      state.multiSectionList.push({
        order: 0,
        name: '',
        files: [],
      });

      state.multiSectionList.forEach((section, index) => {
        section.order = index;
      });
    },
    deleteSection(state, id) {
      state.multiSectionList.splice(id, 1);

      state.multiSectionList.forEach((section, index) => {
        section.order = index;
      });
    },
    addSectionFile(state, { fileData, sectionId }) {
      state.multiSectionList[sectionId].files.push(fileData);
    },
    deleteSectionFile(state, { fileId, sectionId }) {
      state.multiSectionList[sectionId].files.splice(fileId, 1);

      state.multiSectionList.forEach((section) => {
        section.files.forEach((file, index) => {
          file.displayOrder = index;
        });
      });
    },
    selectSectionFile(state, { fileId, sectionId }) {
      state.multiSectionList.forEach((section) => {
        section.files.forEach((file) => {
          file.selected = false;
        });
      });

      Vue.set(state.multiSectionList[sectionId].files, fileId, {
        ...state.multiSectionList[sectionId].files[fileId],
        selected: true,
      });
    },
    updateSectionFiles(state, { fileData, sectionId }) {
      state.multiSectionList[sectionId].files = fileData;

      state.multiSectionList.forEach((section) => {
        section.files.forEach((file, index) => {
          file.displayOrder = index;
        });
      });
    },
    updateSectionName(state, { sectionName, sectionId }) {
      state.multiSectionList[sectionId].name = sectionName;
    },
    updateSectionFileOrder(state) {
      state.multiSectionList.forEach((section) => {
        section.files.forEach((file, index) => {
          file.displayOrder = index;
        });
      });
    },
    resetMultiSectionSelectedFile(state) {
      state.multiSectionList.forEach((section) => {
        section.files.forEach((file) => {
          file.selected = false;
        });
      });
    },

    /**
     * Single section mutations
    */
    addSingleSectionFile(state, fileData) {
      state.singleSectionList.push(fileData);
    },
    deleteSingleSectionFile(state, fileId) {
      state.singleSectionList.splice(fileId, 1);

      state.singleSectionList.forEach((file, index) => {
        file.displayOrder = index;
      });
    },
    selectSingleSectionFile(state, fileId) {
      state.singleSectionList.forEach((file) => {
        file.selected = false;
      });

      Vue.set(state.singleSectionList, fileId, {
        ...state.singleSectionList[fileId],
        selected: true,
      });
    },
    updateSingleSectionFiles(state, fileData) {
      state.singleSectionList = fileData;

      state.singleSectionList.forEach((file, index) => {
        file.displayOrder = index;
      });
    },
    updateSingleSectionFileOrder(state) {
      state.singleSectionList.forEach((file, index) => {
        file.displayOrder = index;
      });
    },
    resetSingleSectionSelectedFile(state) {
      state.singleSectionList.forEach((file) => {
        file.selected = false;
      });
    },

    /**
     * Common
    */
    toggleSections(state, payload) {
      state.sectionsMode = payload;
    },
    setSectionValidationKey(state, payload) {
      state.sectionValidationKey = payload;
    },
  },

  actions: {
    async loadFilePages({ commit, rootState }, payload) {
      const { parsedFiles, sectionId = null } = payload;
      let fileIndex = 0;

      parsedFiles.forEach(async (fileData) => {
        commit('set', {
          loading: {
            file: true,
          },
          currentUploadSectionId: sectionId,
        });

        try {
          const parsedFile = await toBase64(fileData.file);

          const { data } = await api.menu.getFilePages({
            locationId: rootState.user.location.id,
            fileName: fileData.fileName,
            data: parsedFile,
          });

          if (data) {
            if (sectionId !== null) {
              commit('addSectionFile', {
                fileData: {
                  ...fileData,
                  fileId: data.fileId,
                  pages: data.pages,
                  status: data.status,
                  fileSize: data.fileSize > 1e6
                    ? `${fromBytesToMb(data.fileSize)} Mb`
                    : `${fromBytesToKb(data.fileSize)} Kb`,
                },
                sectionId,
              });
            } else {
              commit('addSingleSectionFile', {
                ...fileData,
                fileId: data.fileId,
                pages: data.pages,
                status: data.status,
                fileSize: data.fileSize > 1e6
                  ? `${fromBytesToMb(data.fileSize)} Mb`
                  : `${fromBytesToKb(data.fileSize)} Kb`,
              });
            }
          }
        } catch (e) {
          throw e;
        } finally {
          fileIndex += 1;

          if (fileIndex === parsedFiles.length) {
            if (sectionId !== null) {
              commit('updateSectionFileOrder');
            } else {
              commit('updateSingleSectionFileOrder');
            }

            commit('set', {
              loading: {
                file: false,
              },
              currentUploadSectionId: null,
            });
          }
        }
      });
    },
    async loadFiles({ commit, rootState }) {
      try {
        const { data } = await api.menu.getFiles({
          locationId: rootState.user.location.id,
        });

        if (data.length) {
          const isSectionMode = !data.some(({ section }) => section === null);
          commit('toggleSections', isSectionMode);

          if (isSectionMode) {
            const parsedFiles = parseToMultiSectionList(data);

            commit('set', { multiSectionList: parsedFiles });
            commit('selectSectionFile', {
              fileId: 0,
              sectionId: 0,
            });
          } else {
            const parsedFiles = parseToSingleSectionList(data);

            commit('set', { singleSectionList: parsedFiles });
            commit('selectSingleSectionFile', 0);
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async sendData({ state, rootState }, payload) {
      const { preview, ...contactInfo } = payload;
      let parsedFiles = [];

      if (state.sectionsMode) {
        parsedFiles = parseFromMultiSectionList(state.multiSectionList);
      } else {
        parsedFiles = parseFromSingleSectionList(state.singleSectionList);
      }

      try {
        await api.menu.setMenuData({
          locationId: rootState.user.location.id,
          files: parsedFiles,
          contactInfo,
          preview,
        });
      } catch (e) {
        throw e;
      }
    },
  },
});
