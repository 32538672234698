import store from '@/store';
import isWelcomeScreendEnabled from '@/utils/isWelcomeScreendEnabled';

export default async (to, from, next) => {
  if (store.getters['auth/isGuest']) {
    next({
      name: isWelcomeScreendEnabled() ? 'welcome' : 'login',
      query: {
        path: to.fullPath,
        name: to.name,
      },
    });
  } else {
    next();
  }
};
