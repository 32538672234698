/* eslint-disable no-shadow */

import { isArray, isObject, isFunction } from 'lodash';

export default function ({
  state = {},
  mutations = {},
  actions = {},
  ...other
}) {
  /**
   * Default state.
   *
   * @return {Object}
   */
  function getDefaults() {
    if (isFunction(state)) {
      return state();
    }

    return {
      ...state,
    };
  }

  return {
    state: getDefaults(),
    mutations: {
      set(state, value) {
        Object.entries(value).forEach(([key, data]) => {
          if (!isArray(state[key]) && isObject(state[key])) {
            state[key] = {
              ...state[key],
              ...data,
            };
          } else {
            state[key] = data;
          }
        });
      },
      reset(state) {
        Object.assign(state, getDefaults());
      },
      ...mutations,
    },
    actions: {
      getDefaults,
      ...actions,
    },
    ...other,
  };
}
