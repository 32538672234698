<template>
  <span></span>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

export default {

  computed: {
    ...mapState('auth', [
      'viewAs',
    ]),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
    }),
  },

  async mounted() {
    const returnRoute = this.viewAs?.returnPath
      ? { path: this.viewAs.returnPath }
      : { name: 'home' };

    try {
      await this.logoutAs();

      this.resetStore('auth');
      this.$router.push(returnRoute);

      return;
    } catch (e) {
      this.$showServerError(e);
    }

    this.$router.push({ name: 'profile' });
  },

  methods: {
    ...mapActions({
      logoutAs: 'auth/logoutAs',
      resetStore: 'resetStore',
    }),
  },
};
</script>
