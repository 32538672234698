import NotFound from '@/pages/404.vue';
import Login from '@/pages/Login.vue';
import Logout from '@/pages/Logout.vue';
import Token from '@/pages/Token.vue';
import ViewAsLogout from '@/pages/ViewAsLogout.vue';
import Recovery from '@/pages/Recovery.vue';
import Dashboard from '@/pages/Dashboard.vue';
import Welcome from '@/pages/Welcome.vue';
import Onboarding from '@/pages/Onboarding.vue';
import ChangePassword from '@/pages/ChangePassword.vue';

import auth from '@/middleware/auth';
import checkUpdate from '@/middleware/check-update';
import checkPassword from '@/middleware/check-password';
import checkOnboarding from '@/middleware/check-onboarding';
import checkNymOnboarding from '@/middleware/check-nym-onboarding';
import checkTerms from '@/middleware/check-terms';
import checkNewapp from '@/middleware/check-newapp';
import checkRedirects from '@/middleware/check-redirects';
import avatar from '@/middleware/avatar';
import guest from '@/middleware/guest';
import user from '@/middleware/user';
import payouts from '@/middleware/payouts';
import newAppDefault from '@/middleware/new-app-default';

import store from '@/store';

const Profile = () => import('@/pages/Profile.vue');
const Reporting = () => import('@/pages/Reporting.vue');
const Reviews = () => import('@/pages/Reviews.vue');
const Distributions = () => import('@/pages/Distributions.vue');
const Alerts = () => import('@/pages/Alerts.vue');
const Support = () => import('@/pages/Support.vue');
const Transactions = () => import('@/pages/Transactions.vue');
const Payouts = () => import('@/pages/Payouts.vue');
const LocationView = () => import('@/pages/LocationView.vue');
const Overview = () => import('@/pages/Overview.vue');
const Locations = () => import('@/pages/Locations.vue');
const TermsAndConditions = () => import('@/pages/TermsAndConditions.vue');
const PrivacyPolicy = () => import('@/pages/PrivacyPolicy.vue');
const Promotions = () => import('@/pages/Promotions.vue');
const PromotionView = () => import('@/pages/PromotionView.vue');
const Notifications = () => import('@/pages/Notifications.vue');
const NotificationView = () => import('@/pages/NotificationView.vue');
const OnlineMenu = () => import('@/pages/OnlineMenu.vue');
const RegisteredUsers = () => import('@/pages/RegisteredUsers.vue');
const RecoverySuccess = () => import('@/pages/RecoverySuccess.vue');
const RecoveryError = () => import('@/pages/RecoveryError.vue');
const AppUpdate = () => import('@/pages/AppUpdate.vue');
const NewApp = () => import('@/pages/NewApp.vue');
const OnboardOnfido = () => import('@/pages/OnboardOnfido.vue');

/**
 * Create middleware related by sidebar menu visibility.
 *
 * @param {string} name
 * @returns {Function}
 */
function sidebar(name) {
  return (to, from, next) => {
    if (store.getters['sidebar/access'][name]) {
      next();
    } else {
      next({ name: 'home' });
    }
  };
}

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/app-update',
    name: 'app-update',
    component: AppUpdate,
  },
  {
    path: '/recovery-success',
    name: 'recovery-success',
    component: RecoverySuccess,
    meta: {
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/recovery-error',
    name: 'recovery-error',
    component: RecoveryError,
    meta: {
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/newapp',
    name: 'newapp',
    component: NewApp,
    props: true,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/token',
    name: 'token',
    component: Token,
    props: ({ query }) => ({
      expiresInSeconds: parseInt(query?.expiresInSeconds || 0, 10),
      accessToken: query?.accessToken,
      refreshToken: query?.refreshToken,
      returnPath: query?.returnPath,
      redirectPath: query?.redirectPath,
    }),
  },
  {
    path: '/view-as-logout',
    name: 'view-as-logout',
    component: ViewAsLogout,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: Recovery,
    meta: {
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/onboarding-completed',
    name: 'onboarding-completed',
    component: Welcome,
    meta: {
      middleware: [
        auth,
      ],
    },
    props: {
      onboardingCompleted: true,
    },
  },
  {
    path: '/onboard-onfido',
    name: 'onboard-onfido',
    component: OnboardOnfido,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    meta: {
      middleware: [
        auth,
        user,
        checkUpdate,
        avatar,
        checkTerms,
      ],
    },
    props: ({ query }) => ({
      step: +query.step,
    }),
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      middleware: [
        auth,
        user,
        checkUpdate,
      ],
    },
  },
  {
    path: '/',
    component: Dashboard,
    meta: {
      middleware: [
        newAppDefault,
        auth,
        user,
        checkUpdate,
        checkOnboarding,
        checkNymOnboarding,
        checkPassword,
        checkNewapp,
        checkRedirects,
        avatar,
      ],
    },
    children: [
      {
        path: '/',
        name: 'home',
        redirect: {
          name: 'profile',
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          title: 'profile.title',
        },
      },
      {
        path: '/reporting',
        name: 'reporting',
        component: Reporting,
        meta: {
          title: 'reporting.title',
          middleware: [
            sidebar('reporting'),
          ],
        },
      },
      {
        path: '/reviews',
        name: 'reviews',
        component: Reviews,
        meta: {
          title: 'reviews.title',
          middleware: [
            sidebar('reviews'),
          ],
        },
      },
      {
        path: '/online-menu',
        name: 'online-menu',
        component: OnlineMenu,
        meta: {
          title: 'onlineMenu.title',
          middleware: [
            sidebar('onlineMenu'),
          ],
        },
      },
      {
        path: '/distributions',
        name: 'distributions',
        component: Distributions,
        meta: {
          title: 'distributions.title',
          middleware: [
            sidebar('distributions'),
          ],
        },
      },
      {
        path: '/distributions-manager',
        name: 'distributions-manager',
        component: Distributions,
        meta: {
          title: 'distributions master',
          middleware: [
            sidebar('distributions'),
          ],
        },
      },
      {
        path: '/distributions-account',
        name: 'distributions-account',
        component: Distributions,
        meta: {
          title: 'distributions account',
          middleware: [
            sidebar('distributions'),
          ],
        },
      },
      {
        path: '/alerts',
        name: 'alerts',
        component: Alerts,
        meta: {
          title: 'alerts.title',
          middleware: [
            sidebar('alerts'),
          ],
        },
      },
      {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
          title: 'support.title',
          middleware: [
            sidebar('support'),
          ],
        },
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: Transactions,
        meta: {
          title: 'transactions.title',
          middleware: [
            sidebar('transactions'),
          ],
        },
      },
      {
        path: '/payouts',
        name: 'payouts',
        component: Payouts,
        meta: {
          title: 'payouts.title',
          middleware: [
            sidebar('payouts'),
            payouts,
          ],
        },
      },
      {
        path: '/overview',
        name: 'overview',
        component: Overview,
        meta: {
          title: 'Perfomance',
          middleware: [
            sidebar('overview'),
          ],
        },
      },
      {
        path: '/locations/:locationId',
        name: 'location-view',
        component: LocationView,
        meta: {
          title: 'registeredLocation.title',
          middleware: [
            sidebar('locations'),
          ],
        },
        props: ({ params }) => ({
          locationId: +params.locationId,
        }),
      },
      {
        path: '/locations',
        name: 'locations',
        component: Locations,
        meta: {
          title: 'locations.title',
          middleware: [
            sidebar('locations'),
          ],
        },
      },
      {
        path: '/promotions/:promotionId',
        name: 'promotion-view',
        component: PromotionView,
        meta: {
          title: 'promotions.view.title',
          middleware: [
            sidebar('promotions'),
          ],
        },
        props: true,
      },
      {
        path: '/promotions',
        name: 'promotions',
        component: Promotions,
        meta: {
          title: 'promotions.title',
          middleware: [
            sidebar('promotions'),
          ],
        },
      },
      {
        path: '/notifications/:notificationId',
        name: 'notification-view',
        component: NotificationView,
        meta: {
          title: 'notifications.title',
          middleware: [
            sidebar('notifications'),
          ],
        },
        props: true,
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
          title: 'notifications.title',
          middleware: [
            sidebar('notifications'),
          ],
        },
      },
      {
        path: '/terms-and-conditions',
        name: 'terms',
        component: TermsAndConditions,
        meta: {
          title: 'terms.title',
          middleware: [
            sidebar('terms'),
          ],
        },
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        meta: {
          title: 'privacy.title',
          middleware: [
            sidebar('privacy'),
          ],
        },
      },
      {
        path: '/registered-users',
        name: 'registered-users',
        component: RegisteredUsers,
        meta: {
          title: 'registeredUsers.title',
          middleware: [
            sidebar('registeredUsers'),
          ],
        },
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];
