import Vue from 'vue';
import { capitalize } from 'lodash';
import money from './money';
import date from './date';
import time from './time';

Vue.filter('money', money);
Vue.filter('date', date);
Vue.filter('time', time);
Vue.filter('capitalize', capitalize);
