export default ({
  locationId,
  staffId,
  preview = false,
  amount = null,
  rate = null,
}) => {
  const params = preview ? `&preview=1&amount=${amount}&rate=${rate}` : '';

  return `${process.env.VUE_APP_PAY_URL}/${locationId}?staffId=${staffId}${params}`;
};
