<template>
  <div class="change-password">
    <div class="change-password__header">
      <ui-icon
        width="90px"
        height="24px"
        name="easytip-horizontal"
      />
    </div>
    <div class="change-password__body">
      <div v-if="!status.success && !status.failed">
        <div class="change-password__title">
          Сhange your<br/>password
        </div>
        <div class="change-password__description">
          For security reasons, please change the one-time password.
          Your new password must contain at least 8 characters including
          one number and one Latin letter.
        </div>
      </div>
      <div class="change-password__card">
        <password-change-status
          v-if="status.success"
          title="Success"
          description="Your password has been changed successfully. Use your new password to log in."
          icon="change-password-success"
          @submit="logoutHandler"
        />
        <password-change-status
          v-else-if="status.failed"
          title="Error"
          description="Sorry, something went wrong. Please try again later."
          icon="change-password-error"
          @submit="toOnboarding"
        />
        <template v-else>
          <validation-observer
            v-slot="{ handleSubmit }"
            tag="form"
            class="change-password__form"
          >
            <validation-provider
              tag="div"
              vid="newPassword"
              :name="$t('form.labels.newPassword')"
              rules="required|min:8|max:30|password"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-password
                v-model="form.newPassword"
                :label="$t('form.labels.newPassword')"
                maxlength="30"
                minlength="8"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              ref="newPasswordConfirm"
              :name="$t('form.labels.repeatPassword')"
              rules="required|min:8|max:30|password|confirmedPassword:newPassword"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-password
                v-model="form.newPasswordConfirm"
                :label="$t('form.labels.repeatPassword')"
                maxlength="30"
                minlength="8"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <new-button
              action
              :is-loading="loading"
              :disabled="loading"
              class="change-password__submit-btn"
              @click.prevent="handleSubmit(send)"
            >
              Update
            </new-button>
          </validation-observer>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PasswordChangeStatus from '@/components/PasswordChangeStatus.vue';

export default {
  name: 'ChangePassword',

  components: {
    PasswordChangeStatus,
  },

  data() {
    return {
      loading: false,
      form: {
        newPassword: '',
        newPasswordConfirm: '',
      },
      status: {
        success: false,
        failed: false,
      },
    };
  },

  methods: {
    ...mapActions('user', [
      'updateGeneratedPassword',
    ]),
    async send() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await this.updateGeneratedPassword(this.form);

        this.status.success = true;
      } catch (e) {
        this.status.failed = true;
      } finally {
        this.loading = false;
      }
    },
    logoutHandler() {
      this.$router.push({ name: 'logout' });
    },
    toOnboarding() {
      this.$router.push({ name: 'onboarding' });
    },
  },
};
</script>
