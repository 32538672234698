import { DateTime } from 'luxon';

/**
 * Convert value to native Date.
 *
 * @param  {*} value
 * @param  {boolean} defaultDate
 * @return {Date}
 */
export default (value, defaultDate = true) => {
  if (value instanceof Date) {
    return value;
  }

  if (value instanceof DateTime) {
    return value.toJSDate();
  }

  if (typeof value === 'string') {
    value = value.trim();

    if (/^\d{4}(-\d{2}){2}$/.test(value)) {
      return DateTime.fromSQL(value).toJSDate();
    }

    if (/^\d{4}(-\d{2}){2}T\d{2}(:\d{2}){2}$/.test(value)) {
      value = `${value}Z`;
    }

    if (value) {
      return DateTime.fromISO(value).toJSDate();
    }
  }

  if (typeof value === 'number') {
    return new Date(value * 1000);
  }

  if (defaultDate) {
    return new Date();
  }

  return null;
};
