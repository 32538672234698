<template>
  <app-onboarding>
    <recovery
      onboarding
      @confirm="confirm"
      @goLogin="goLogin"
    />
  </app-onboarding>
</template>

<script>
import Recovery from '@/components/Recovery.vue';
import AppOnboarding from '@/components/AppOnboarding.vue';

export default {
  components: {
    AppOnboarding,
    Recovery,
  },

  computed: {
  },

  methods: {
    confirm() {
      this.$router.push({ name: 'recovery-success' });
    },
    goLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
