import { DateTime } from 'luxon';

/**
 * Convert value to SQL date string.
 *
 * @param {*} value date
 * @return {string}
 */
function toSQLDate(value) {
  if (value instanceof Date) {
    value = DateTime.fromJSDate(value);
  }

  if (value instanceof DateTime) {
    return value.toSQLDate();
  }

  return null;
}

export default toSQLDate;
