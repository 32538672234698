import errorCodes from '@/config/errorCodes';
import i18n from '@/plugins/i18n';

export default (errorCode, dynamicData) => {
  const { type } = errorCodes?.[errorCode] || errorCodes.default;
  const code = errorCodes?.[errorCode] ? errorCode : 'default';
  const message = i18n.t(`errorMessages.${code}`, dynamicData);

  return {
    type,
    message,
  };
};
