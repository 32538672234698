import store from '@/store';

export default async (to, from, next) => {
  if (!store.state.user.infoLoaded) {
    try {
      await store.dispatch('user/loadInfo');
    } catch (e) {
      store.commit('auth/removeToken');

      next({
        name: 'login',
        query: {
          path: to.fullPath,
          name: to.name,
        },
      });

      return;
    }
  }

  next();
};
