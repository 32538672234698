<script>
import { mapActions } from 'vuex';

export default {

  async mounted() {
    try {
      await this.logout();
    } catch (e) {
      // this.$showServerError(e);
    } finally {
      this.$router.push({ name: 'login' });
    }
  },

  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
  },

  render: () => null,
};
</script>
