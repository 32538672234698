import api from '@/api';
import i18n from '@/plugins/i18n';
import money from '@/filters/money';
import CARD_TYPES from '@/config/cardTypes';

import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    checkoutPublicKey: null,
    payoutPlans: [],
    payoutPlansLoading: false,
    currentPlan: null,
    currentPlanLoading: false,
    currentProgress: null,
    payoutInstantDraft: null,
    currentCard: null,
    sharedCardStaffValues: [
      { value: 'my-card', label: 'My card' },
      { value: 'employer-card', label: 'Employer card' },
    ],
    selectedCardType: null,
  }),

  getters: {
    computedPayoutPlans(state) {
      const icons = ['coin', 'coins-stack', 'coins-bag'];

      const promoDaily = state.payoutPlans
        .find(({ activePromo, period }) => activePromo && period === 'DAILY');

      return state.payoutPlans.map((plan) => ({
        id: plan.id,
        title: money(plan.targetAmount, plan.currency, { fractionDigits: 0 }),
        info: plan.feeAmount
          ? {
            value: money(plan.feeAmount, plan.currency),
            description: i18n.t('payoutBalance.fee'),
          }
          : {
            value: i18n.t('payoutBalance.free'),
            description: i18n.t('payoutBalance.payout'),
          },
        example: [
          plan.example,
        ],
        description: plan.description,
        header: plan.name || '',
        value: plan.targetAmount,
        icon: icons.shift(),
        periodPayedDescription: plan.periodPayedDescription,
        period: plan.period,
        activePromo: plan.activePromo,
        promoDaysCnt: plan.promoDaysCnt,
        disabled: promoDaily ? promoDaily.id !== plan.id : false,
      }));
    },
    currentPlanId(state) {
      return state.currentPlan?.id || '';
    },
    isPersonalCardDisabled(state, getters, rootState, rootGetters) {
      const disableCommonPersonalCard = rootGetters['user/isCommon'] && !rootGetters['auth/isStaff'];
      const disableIndividualPersonalCard = !rootGetters['user/isCommon'] && !rootGetters['auth/isStaff'] && !rootState.user.location.isAllowedSharedCard;
      return disableCommonPersonalCard || disableIndividualPersonalCard;
    },
    isCompanyCardAvailable(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isOwnerCommon']
        && rootGetters['user/isUAE']
        && (rootState.user.location.isAllowedSharedCard || rootState.user.location.workplaceFee);
    },
    cardTypeList(state, getters, rootState) {
      return [
        {
          label: i18n.t('Personal card'),
          value: CARD_TYPES.PERSONAL,
          visible: rootState.user.showManagePayout,
        },
        {
          label: i18n.t('Company card'),
          value: CARD_TYPES.CORPORATE,
          visible: rootState.user.showManageVenuePayout,
        },
      ];
    },
    availableCardTypeList(state, getters) {
      return getters.cardTypeList.filter(({ visible }) => visible);
    },
    hasDifferentCardTypes(state, getters) {
      return getters.availableCardTypeList.length > 1;
    },
    instantButtonState(state) {
      return state.currentProgress?.instantButtonState;
    },
  },

  mutations: {
    setCheckoutPublicKey(state, publicKey) {
      state.checkoutPublicKey = publicKey;
    },
    setPayoutPlans(state, payoutPlans) {
      state.payoutPlans = payoutPlans;
    },
    setCurrentPlan(state, currentPlan) {
      state.currentPlan = currentPlan;
    },
    setCurrentProgress(state, currentProgress) {
      state.currentProgress = currentProgress;
    },
    setPayoutInstantDraft(state, draft) {
      state.payoutInstantDraft = draft;
    },
    setCurrentCard(state, card) {
      state.currentCard = card;
    },
  },

  actions: {
    setCurrentCard({ commit }, card) {
      commit('setCurrentCard', card);
    },
    setSelectedCardType({ commit }, cardType) {
      commit('set', { selectedCardType: cardType });
    },
    initPayouts({ dispatch, rootState, rootGetters }) {
      const { isAllowedSharedCard, isSharedCard } = rootState.user.profile;

      if (rootGetters['auth/isOnlyStaff']
        && isAllowedSharedCard
        && isSharedCard
      ) {
        return Promise.all([
          rootGetters['user/isProfilePayerMax'] ? false
            : dispatch('getCheckoutPublicKey'),
          dispatch('getCurrentProgress'),
        ]);
      }

      return Promise.all([
        rootGetters['user/isProfilePayerMax'] ? false
          : dispatch('getCheckoutPublicKey'),
        dispatch('getPayoutPlans'),
        dispatch('getCurrentPlan'),
        dispatch('getCurrentProgress'),
      ]);
    },
    async createDraftInstantPayout({ rootState, commit }) {
      const userId = rootState.user.profile.id;
      const { data } = await api.checkout.createDraftInstantPayout(userId);

      commit('setPayoutInstantDraft', data);
    },
    async makeInstantPayout({ rootState, state }) {
      const userId = rootState.user.profile.id;

      await api.checkout.makeInstantPayout({
        userId,
        instantPayoutId: state.payoutInstantDraft.id,
      });
    },
    async getCheckoutPublicKey({ commit, rootState }) {
      const { data } = await api.checkout.getCheckoutPublicKey(rootState.user.location.payoutId);

      commit('setCheckoutPublicKey', data.publicKey);
    },
    async getPayoutPlans({ commit, rootState }) {
      const { user } = rootState;
      try {
        commit('set', { payoutPlansLoading: true });
        const { data } = await api.checkout.getPayoutPlans(user.location.id, user.profile.payoutPricePlanType);

        commit('setPayoutPlans', data);
      } catch (e) {
        console.log(e);
      } finally {
        commit('set', { payoutPlansLoading: false });
      }
    },
    async getCurrentPlan({ commit, rootState }) {
      try {
        commit('set', { currentPlanLoading: true });
        const { data } = await api.checkout.getCurrentPlan(rootState.user.profile.id);

        commit('setCurrentPlan', data);
      } catch (e) {
        console.log(e);
      } finally {
        commit('set', { currentPlanLoading: false });
      }
    },
    async getCurrentProgress({ commit, rootState, rootGetters }) {
      if (rootGetters['auth/isOnlyOwner'] && !rootState.user.location.isAllowedSharedCard) {
        return;
      }

      try {
        const { data } = await api.checkout.getCurrentProgress(rootState.user.profile.id);

        commit('setCurrentProgress', data);
      } catch (e) {
        console.log(e);
      }
    },
    async selectNewPlan({ rootState, dispatch, commit }, payoutPlanId) {
      const { data } = await api.checkout.selectNewPlan({
        userId: rootState.user.profile.id,
        payoutPlanId,
      });

      commit('setCurrentPlan', data);
      dispatch('getCurrentProgress');
    },
  },
});
