/* eslint-disable no-shadow, camelcase */
import { extend } from 'vee-validate';
import {
  required,
  confirmed,
  min,
  max,
  max_value,
  min_value,
  numeric,
  email,
} from 'vee-validate/dist/rules';
import age from '@/utils/age';
import isUrl from '@/utils/isUrl';
import i18n from '@/plugins/i18n';

extend('required', {
  params: ['invalid'],
  validate(value, { invalid }) {
    if (invalid) {
      return {
        required: true,
        valid: false,
      };
    }

    return required.validate(value);
  },
  message: () => i18n.t('validationErrors.required'),
  computesRequired: true,
});

extend('confirmedPassword', {
  ...confirmed,
  message: () => i18n.t('validationErrors.confirmedPassword'),
});

extend('min', {
  validate(value, length) {
    if (!min.validate(value, { length })) {
      return i18n.t('validationErrors.min', { length });
    }

    return true;
  },
});

extend('max', {
  validate(value, length) {
    if (!max.validate(value, { length })) {
      return i18n.t('validationErrors.max', { length });
    }

    return true;
  },
});

extend('max_value', {
  validate(value, max) {
    if (!max_value.validate(value, { max })) {
      return i18n.t('validationErrors.max_value', { max });
    }

    return true;
  },
});

extend('min_value', {
  validate(value, min) {
    if (!min_value.validate(value, { min })) {
      return i18n.t('validationErrors.min_value', { min });
    }

    return true;
  },
});

extend('password', {
  validate(value) {
    if (!/\d/.test(value) || !/[a-zA-Z]/.test(value)) {
      return i18n.t('validationErrors.password');
    }

    return true;
  },
});

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validationErrors.numeric'),
});

extend('decimal', {
  validate(value) {
    if (/^(0|[1-9]\d*(\.\d+)?)$/.test(value) && !Number.isNaN(parseFloat(value))) {
      return true;
    }

    return i18n.t('validationErrors.decimal');
  },
});

extend('email', {
  ...email,
  message: () => i18n.t('validationErrors.email'),
});

extend('url', {
  validate(value) {
    if (isUrl(value)) {
      return true;
    }

    return i18n.t('validationErrors.url');
  },
});

extend('adult', {
  validate(value) {
    if (age(value) < 16) {
      return i18n.t('validationErrors.adult');
    }

    return true;
  },
});

extend('lowerLetter', {
  validate(value) {
    if (/[A-Z]/g.test(value)) {
      return i18n.t('validationErrors.lowerLetter');
    }

    return true;
  },
});

extend('cardholderName', {
  validate(value) {
    if (/^(\s*[A-Za-z]{2,}\s([A-Za-z]+\s)*[A-Za-z]+\s*)$/.test(value)) {
      return true;
    }

    return i18n.t('validationErrors.cardholderName');
  },
});

extend('float', {
  validate(value) {
    if (/^-?\d*(\.\d+)?$/.test(value)) {
      return true;
    }

    return i18n.t('validationErrors.float');
  },
});
