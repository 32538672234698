import * as Sentry from '@sentry/vue';

export default function createSentry() {
  return (store) => {
    store.subscribe((mutation) => {
      if (mutation.type === 'user/set' && mutation.payload?.profile) {
        Sentry.configureScope((scope) => {
          scope.clear();
          scope.setUser(mutation.payload?.profile);
        });
      }
    });
  };
}
