/**
 * Parse content-disposition header.
 *
 * @param {string} value
 * @returns {string}
 */
function getContentDispositionFilename(headers) {
  const header = headers['content-disposition'];

  if (!header) {
    return null;
  }

  return header.split('filename=')[1];
}

export {
  getContentDispositionFilename,
};
