import { DateTime } from 'luxon';
import parseDate from '@/utils/parseDate';

/**
 * Date formatting.
 *
 * @example
 *
 * Oct 14, 1983
 *
 * @param  {*} value
 * @param  {string} format
 * @return {string}
 */
export default (value, format = 'dd.LL.yyyy') => {
  const parsed = parseDate(value, false /* defaultDate */);

  return parsed
    ? DateTime.fromJSDate(parsed).toFormat(format)
    : '';
};
