import getErrorByCode from '@/utils/getErrorByCode';

/**
 * Create notify configuration.
 *
 * @param {Error|Object} e
 * @param {Object} dynamicData (phoneNumber, address etc. in error description)
 * @return {Object}
 */

function createServerErrorConfig(e, dynamicData = {}) {
  const { message, type } = getErrorByCode(e?.response?.data?.errorCode, dynamicData);

  if (e instanceof Error) {
    return {
      message,
      type,
      icon: 'notify-icon',
    };
  }

  return {
    message,
    type,
    icon: 'notify-icon',
    ...e || {},
  };
}

export default (Vue) => {
  Vue.mixin({

    methods: {
      $showSuccess(params) {
        this.$notify({
          message: 'Success!',
          type: 'success',
          icon: 'notify-icon',
          ...params || {},
        });
      },
      $showServerError(e, dynamicData = {}) {
        this.$notify(
          createServerErrorConfig(e, dynamicData),
        );
      },
    },
  });
};
