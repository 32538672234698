<template>
  <div v-if="countryChecked" class="welcome">
    <div class="welcome__header">
      <ui-icon
        width="90px"
        height="24px"
        name="easytip-horizontal"
      />
    </div>
    <div
      class="welcome__body"
      :class="{'welcome__body_completed': onboardingCompleted }"
    >
      <div
        v-if="onboardingCompleted"
        class="welcome__page welcome__page_visible"
      >
        <div class="welcome__image-wrapper">
          <div class="welcome__image-container">
            <template v-if="!isCommon && !isStaff">
              <img
                v-if="isUAE"
                src="@/assets/images/welcome/onboarding-completed-overview-uae.png"
                alt="onboarding-completed"
                class="welcome__onboarding-completed-img"
              >
              <img
                v-else
                src="@/assets/images/welcome/onboarding-completed-overview.png"
                alt="onboarding-completed"
                class="welcome__onboarding-completed-img"
              >
            </template>
            <div v-else class="welcome__iframe-container">
              <iframe
                class="welcome__iframe"
                v-if="isIframeShow"
                v-show="!payLoading"
                :src="getStaffPayUrl"
                @load="payLoaded"
              ></iframe>

              <ui-loading
                v-if="payLoading"
                inline
                class="welcome__iframe-loader"
              />
            </div>
          </div>
        </div>
        <div class="welcome__subtitle">
          Registration completed
        </div>
        <div class="welcome__title">
          Start earning tips today!
        </div>
      </div>
      <template v-else>
        <div
          class="welcome__page"
          :class="{ 'welcome__page_visible': currentPage === 1 }"
        >
          <div class="welcome__image-wrapper">
            <img
              v-if="isUAE"
              src="@/assets/images/welcome/welcome-1-uae.png"
              alt="welcome"
              class="welcome__image"
            >
            <img
              v-else
              src="@/assets/images/welcome/welcome-1.png"
              alt="welcome"
              class="welcome__image"
            >
          </div>
          <div class="welcome__title">
            Welcome<br/>
            to <span class="welcome__title_red">EasyTip!</span>
          </div>
        </div>
        <div
          class="welcome__page"
          :class="{ 'welcome__page_visible': currentPage === 2 }"
        >
          <div class="welcome__image-wrapper">
            <img
              v-if="isUAE"
              src="@/assets/images/welcome/welcome-2-uae.png"
              alt="welcome"
              class="welcome__image"
            >
            <img
              v-else
              src="@/assets/images/welcome/welcome-2.png"
              alt="welcome"
              class="welcome__image"
            >
          </div>
          <div class="welcome__title">
            Take control of your<br/>
            income and
            <span class="welcome__title_red">
              earn<br/>
              more tips!
            </span>
          </div>
        </div>
        <div
          class="welcome__page"
          :class="{ 'welcome__page_visible': currentPage === 3 }"
        >
          <div class="welcome__image-wrapper">
            <img
              src="@/assets/images/welcome/welcome-3.png"
              alt="welcome"
              class="welcome__image"
            >
          </div>
          <div class="welcome__title">
            Share your <span class="welcome__title_red">QR code</span><br/>
            with customers!
          </div>
        </div>
        <template v-if="isUAE">
          <div
            class="welcome__page"
            :class="{ 'welcome__page_visible': currentPage === 4 }"
          >
            <div class="welcome__image-wrapper">
              <img
                src="@/assets/images/welcome/welcome-4-card.png"
                alt="welcome"
                class="welcome__image"
              >
            </div>
            <div class="welcome__title">
              Get tips instantly to<br/>
              your <span class="welcome__title_red">EasyTip digital</span><br/>
              card
            </div>
          </div>
          <div
            class="welcome__page"
            :class="{ 'welcome__page_visible': currentPage === 5 }"
          >
            <div class="welcome__image-wrapper">
              <img
                src="@/assets/images/welcome/welcome-5.png"
                alt="welcome"
                class="welcome__image"
              >
            </div>
            <div class="welcome__title">
              Spend your tips how<br/>
              <span class="welcome__title_red">you want to!</span>
            </div>
          </div>
        </template>
        <div
          v-else
          class="welcome__page"
          :class="{ 'welcome__page_visible': currentPage === 4 }"
        >
          <div class="welcome__image-wrapper">
            <img
              src="@/assets/images/welcome/welcome-4.png"
              alt="welcome"
              class="welcome__image"
            >
          </div>
          <div class="welcome__title">
            <span class="welcome__title_red">Get tips instantly</span> and<br/>
            easily track your<br/>
            transactions
          </div>
        </div>
      </template>
    </div>
    <div class="welcome__footer">
      <swipe-pagination
        v-if="!onboardingCompleted"
        :page="currentPage"
        :pages="pagesCount"
        class="welcome__pagination"
      />
      <new-button
        v-if="onboardingCompleted"
        @click="goToChangePassword"
      >
        Let’s go!
      </new-button>
      <new-button
        v-else-if="currentPage === pagesCount"
        @click="finishWelcomeScreensHandler"
      >
        Let’s go!
      </new-button>
      <new-button
        v-else
        type="secondary"
        @click="toNextPage"
      >
        Next
      </new-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import findCountry from '@/utils/findCountry';
import SwipePagination from '@/components/UI/SwipePagination.vue';
import disableWelcomeScreens from '@/utils/disableWelcomeScreens';
import getStaffPayUrl from '@/utils/getStaffPayUrl';

const UAE_CODE = 'AE';

export default {
  name: 'Welcome',

  components: {
    SwipePagination,
  },

  props: {
    onboardingCompleted: Boolean,
  },

  data() {
    return {
      currentPage: 1,
      isUAE: false,
      countryChecked: false,
      payLoading: true,
    };
  },

  computed: {
    ...mapState('user', [
      'profile',
      'location',
    ]),
    ...mapGetters({
      isCommon: 'user/isCommon',
      isStaff: 'auth/isStaff',
    }),
    getStaffPayUrl() {
      return getStaffPayUrl({
        locationId: this.location.payoutId,
        staffId: this.profilePayoutId,
        preview: true,
        amount: 30,
        rate: 5,
      });
    },
    profilePayoutId() {
      return this.profile.staffs
        .find((item) => item.id === this.profile?.staffId && item.workplaceId === this.location?.id)
        ?.payoutId || '';
    },
    isIframeShow() {
      return this.profile?.id && (this.profile.staffs.length || this.isCommon);
    },
    pagesCount() {
      return this.isUAE ? 5 : 4;
    },
  },

  async mounted() {
    if (!this.profile?.staffId) {
      await this.loadUser();
    }

    if (!this.profile.staffs.length) {
      await this.loadUserStaffs();
    }
  },

  methods: {
    ...mapActions('user', ['loadUser', 'loadUserStaffs']),
    toNextPage() {
      this.currentPage += 1;
    },
    goToApp() {
      if (this.profile.enableNymCard) {
        this.$router.push({ name: 'newapp' });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    goToChangePassword() {
      this.$router.push({ name: 'change-password' });
    },
    finishWelcomeScreensHandler() {
      disableWelcomeScreens();

      this.goToApp();
    },
    async checkUAE() {
      const countryCode = await findCountry();
      this.isUAE = countryCode === UAE_CODE;
      this.countryChecked = true;
    },
    payLoaded() {
      this.payLoading = false;
    },
  },

  created() {
    this.checkUAE();
  },
};
</script>
