import api from '@/api';

import factory from '../factory';

export default factory({
  namespaced: true,

  state: () => ({
    loading: false,
    data: null,
    location: null,
    updateLoading: false,
  }),

  getters: {
    isSalon(state) {
      return state.location?.type === 'BEAUTY_SALON'
        || state.location?.type === 'SPA';
    },
    parsedDistributions(state, getters, rootState, rootGetters) {
      const isCommonPriority = state.location?.tipsType === 'COMMON_PRIORITY';
      const distributions = rootGetters['handbook/staffTypes']
        .filter(({ types }) => types.includes(state.location?.type))
        .filter(({ countries }) => countries.includes(state.location.country))
        .map((item) => {
          const isPrimaryStaff = isCommonPriority && item.value === state.location.primaryStaff;

          return {
            staffType: item.value,
            label: item.name,
            isPrimaryStaff,
            percentage: isPrimaryStaff ? state.location.primaryPercentage : 0,
            isDisabled: isPrimaryStaff,
          };
        }).map((item) => {
          const tempData = {};

          if (!item.isPrimaryStaff && state.data) {
            tempData.percentage = state.data.find((v) => v.staffType === item.staffType)?.percentage || 0;
          }

          if (rootGetters['auth/isAdminOrSales'] && !isCommonPriority) {
            tempData.isDisabled = false;
          }

          return {
            ...item,
            ...tempData,
          };
        });

      if (rootGetters['auth/isAdminOrSales'] || !getters.isSalon) {
        return distributions;
      }

      return distributions.filter(({ isDisabled, percentage }) => {
        return !isDisabled || percentage > 0;
      });
    },
  },

  actions: {
    async init({ commit, dispatch }, { location }) {
      commit('set', { location });

      return Promise.all([
        dispatch('handbook/getStaffTypes', null, { root: true }),
        dispatch('loadItems'),
      ]);
    },
    async loadItems({ state, commit }) {
      if (state.loading) {
        return;
      }

      commit('set', { loading: true });

      try {
        const { data } = await api.locations.getDistributions({
          locationId: state.location.id,
        });

        if (data) {
          commit('set', { data });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', { loading: false });
      }
    },
    async updateItems({ state, commit, dispatch }, { payloadData }) {
      if (state.updateLoading) {
        return;
      }

      commit('set', { updateLoading: true });

      try {
        await api.locations.updateDistributions({
          locationId: state.location.id,
          data: payloadData,
        });

        dispatch('loadItems');
      } catch (e) {
        throw e;
      } finally {
        commit('set', { updateLoading: false });
      }
    },
  },
});
