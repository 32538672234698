/**
 * Money formatting.
 *
 * @see {@link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat}
 *
 * @param  {Number|string} amount
 * @param  {string} currency
 * @param  {number} fractionDigits
 * @param  {boolean} floor
 * @param  {options} [options]
 * @return {string}
 */
export default (amount, currency, { fractionDigits = 2, floor = true, ...options } = {}) => {
  if (!currency) {
    return amount;
  }

  if (!fractionDigits && floor) {
    amount = (amount >= 0 ? 1 : -1) * Math.floor(Math.abs(amount));
  }

  currency = currency.toUpperCase();

  const formatOptions = {
    style: 'currency',
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...options,
  };

  const isRtl = currency === 'AED';

  // reverse currency for UAE
  const locales = isRtl ? 'fr-FR' : 'en-US';

  amount = new Intl.NumberFormat(locales, formatOptions).format(amount);

  if (isRtl) {
    return amount.replace(',', '.');
  }

  return amount.replace(',', ' ');
};
