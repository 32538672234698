import api from '@/api';
import getFio from '@/utils/fio';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    loaded: {
      salesPeople: false,
      payButtons: false,
      payoutPlanSetupList: false,
      distributionSchemes: false,
    },
    loading: {
      salesPeople: false,
      payButtons: false,
      payoutPlanSetupList: false,
      distributionSchemes: false,
    },
    payButtons: [],
    payoutPlanSetupList: [],
    salesPeople: [],
    distributionSchemes: [],
  }),

  actions: {
    async loadPayButtons({ commit }, payload) {
      commit('set', {
        loading: {
          payButtons: true,
        },
      });

      try {
        const { data } = await api.locations.getPayButtons(payload);

        if (data) {
          commit('set', {
            loaded: {
              payButtons: true,
            },
            payButtons: data,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', {
          loading: {
            payButtons: false,
          },
        });
      }
    },
    async loadPayoutPlanSetupList({ commit }, payload) {
      commit('set', {
        loading: {
          payoutPlanSetupList: true,
        },
      });

      try {
        const { data } = await api.locations.getPayoutPlanSetupList(payload);

        if (data) {
          commit('set', {
            loaded: {
              payoutPlanSetupList: true,
            },
            payoutPlanSetupList: data,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', {
          loading: {
            payoutPlanSetupList: false,
          },
        });
      }
    },
    async loadSalesPeople({ commit }) {
      commit('set', {
        loading: {
          salesPeople: true,
        },
      });

      try {
        const { data } = await api.locations.getSalesPeople();

        const salesPeople = data.map((item) => ({
          fio: getFio(item),
          ...item,
        }));

        commit('set', {
          loaded: {
            salesPeople: true,
          },
          salesPeople,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('set', {
          loading: {
            salesPeople: false,
          },
        });
      }
    },
    async loadDistributionSchemes({ commit }, payload) {
      commit('set', {
        loading: {
          distributionSchemes: true,
        },
      });

      try {
        const { data } = await api.locations.getDistributionSchemes(payload);

        if (data) {
          commit('set', {
            loaded: {
              distributionSchemes: true,
            },
            distributionSchemes: data,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', {
          loading: {
            distributionSchemes: false,
          },
        });
      }
    },
  },
});
