import axios from 'axios';

/**
 * Find country by ip address.
 *
 * @return {Promise<string>}
 */
const findCountry = (() => {
  let request;

  return () => {
    if (!request) {
      request = axios.get('https://ip2c.org/s')
        .then(({ data }) => data)
        .then((data) => {
          const result = (data || '').toString();

          if (result && result[0] === '1') {
            return result.substr(2, 2).toUpperCase();
          }

          return 'GB';
        })
        .catch(() => 'GB');
    }

    return request;
  };
})();

export default findCountry;
