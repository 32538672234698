<template>
  <div class="onboarding">
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="profileVerificationLoader"
    >
      <bottom-sheet-polling>
        Performing profile verification.<br/>
        Please wait a minute..
      </bottom-sheet-polling>
    </vue-bottom-sheet>
    <onboarding-checklist
      v-if="showChecklist"
      @start-onboarding="showChecklist = false"
    />
    <template v-else>
      <onboarding-header
        v-if="currentStep !== steps.ONFIDO_VERIFICATION"
        :current-step="currentStep"
        :step-count="stepsCount"
        :completed-steps="completedSteps"
        @next-step="nextStep"
        @previous-step="previousStep"
      />
      <onboarding-onfido
        v-if="currentStep === steps.ONFIDO_VERIFICATION"
        :sdk-token="nymCardToken"
        @complete="verifyOnfidoStep"
      />
      <onboarding-main-information
        v-if="currentStep === steps.MAIN_INFORMATION"
        @next-step="profileMainInformationFilledHandler"
      />
      <onboarding-venue-stripe-card
        v-if="currentStep === steps.VENUE_STRIPE_CARD"
        :back-route="showManagePayout ? 'onboarding?step=3' : ''"
        @next-step="nextStep"
      />
      <onboarding-individual-stripe-card
        v-if="currentStep === steps.INDIVIDUAL_STRIPE_CARD"
        @next-step="nextStep"
      />
      <onboarding-kyc-information
        v-if="currentStep === steps.PERSONAL_INFORMATION || currentStep === steps.EMPLOYER_INFORMATION"
        :current-step="currentStep"
        :steps="steps"
        @error="openOnfidoErrorHandler"
        @previous-step="previousStep"
        @next-step="nextStep"
      />
      <template v-else-if="currentStep === steps.PAYOUT_CARD">
        <onboarding-nym-card
          v-if="enableNymCard"
          @next-step="nextStep"
        />
        <onboarding-payout-card
          v-else
          @cancel-onboarding="cancelOnboarding"
          @next-step="nextStep"
        />
      </template>
      <onboarding-payout-plans
        v-else-if="currentStep === steps.PAYOUT_PLAN"
        @cancel-onboarding="cancelOnboarding"
      />
      <onboarding-stepper
        v-if="stepperStepsCount > 1 && !hideStepper"
        :current-step="stepperCurrentStep"
        :step-count="stepperStepsCount"
      />
    </template>
    <vue-bottom-sheet
      ref="vbsOnfidoError"
      :click-to-close="false"
      :swipe-able="false"
      @closed="onfidoErrorAlertClosedHandler"
    >
      <onboarding-onfido-error
        :status-config="onfidoStatusConfig"
        @try-again="tryAgainHandler"
      />
    </vue-bottom-sheet>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapGetters, mapState } from 'vuex';
import OnboardingHeader from '@/components/Onboarding/OnboardingHeader.vue';
import OnboardingStepper from '@/components/Onboarding/OnboardingStepper.vue';
import OnboardingMainInformation from '@/components/Onboarding/OnboardingMainInformation.vue';
import OnboardingPayoutCard from '@/components/Onboarding/OnboardingPayoutCard.vue';
import OnboardingPayoutPlans from '@/components/Onboarding/OnboardingPayoutPlans.vue';
import OnboardingChecklist from '@/components/Onboarding/OnboardingChecklist.vue';
import OnboardingKycInformation from '@/components/Onboarding/OnboardingKycInformation.vue';
import BottomSheetPolling from '@/components/BottomSheet/BottomSheetPolling.vue';
import OnboardingNymCard from '@/components/Onboarding/OnboardingNymCard.vue';
import OnboardingOnfido from '@/components/Onboarding/OnboardingOnfido.vue';
import { NYM_STATUSES } from '@/config/nymStatuses';
import api from '@/api';
import OnboardingIndividualStripeCard from '@/components/Onboarding/OnboardingIndividualStripeCard.vue';
import OnboardingVenueStripeCard from '@/components/Onboarding/OnboardingVenueStripeCard.vue';
import OnboardingOnfidoError from '@/components/Onboarding/Errors/OnboardingOnfidoError.vue';
import { NOBODY } from '@/config/staffTypes';

const GENERAL_STEPS = {
  MAIN_INFORMATION: 1,
};

// Checkout setup
const CHECKOUT_PAYMENT_STEPS = {
  PAYOUT_CARD: 2,
};

const CHECKOUT_PERSONAL_STEPS = {
  PAYOUT_PLAN: 3,
};

// Stripe setup
const STRIPE_OWNER_STEPS = {
  VENUE_STRIPE_CARD: 2,
};

const STRIPE_STAFF_STEPS = {
  INDIVIDUAL_STRIPE_CARD: 2,
};

// ДАННЫЕ КОНСТАНТЫ МОГУТ БЫТЬ ИСПОЛЬЗОВАНЫ ДЛЯ РЕДИРЕКТА СО СТОРОНЫ БЕКЕНДА В СЛУЧАЕ ОШИБОК NYM, МЕНЯТЬ С УЧЕТОМ ЭТОГО
// NYM setup
const NYM_STAFF_STEPS = {
  ONFIDO_VERIFICATION: 2,
  PERSONAL_INFORMATION: 3,
  EMPLOYER_INFORMATION: 4,
  PAYOUT_CARD: 5,
};

const NYM_PERSONAL_STEPS = {
  PAYOUT_PLAN: 6,
};

const NYM_STEPS_FOR_SHOW_IN_STEPPER = {
  [GENERAL_STEPS.MAIN_INFORMATION]: 1,
  [NYM_STAFF_STEPS.ONFIDO_VERIFICATION]: 1,
  [NYM_STAFF_STEPS.PERSONAL_INFORMATION]: 2,
  [NYM_STAFF_STEPS.EMPLOYER_INFORMATION]: 2,
  [NYM_STAFF_STEPS.PAYOUT_CARD]: 3,
  [NYM_PERSONAL_STEPS.PAYOUT_PLAN]: 4,
};

const INITIAL_POLLING_COUNT = 60;

export default {
  name: 'Onboarding',

  components: {
    OnboardingOnfidoError,
    OnboardingIndividualStripeCard,
    OnboardingVenueStripeCard,
    OnboardingNymCard,
    OnboardingOnfido,
    OnboardingKycInformation,
    OnboardingChecklist,
    OnboardingPayoutPlans,
    OnboardingPayoutCard,
    OnboardingMainInformation,
    OnboardingStepper,
    OnboardingHeader,
    BottomSheetPolling,
  },

  props: {
    step: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showChecklist: false,
      currentStep: GENERAL_STEPS.MAIN_INFORMATION,
      pollingCounter: INITIAL_POLLING_COUNT,
      nymCardSessionId: '',
      nymCardToken: '',
      onfidoStatusConfig: {},
      skipStripeOwnerStep: false,
      hideStepper: false,
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      location: (state) => state.user.location,
      showManagePayout: (state) => state.user.showManagePayout,
      showManageVenuePayout: (state) => state.user.showManageVenuePayout,
      currentCard: (state) => state.payouts.currentCard,
    }),
    ...mapGetters({
      isProfileFilled: 'user/isProfileFilled',
      isPersonal: 'user/isPersonal',
      isProfileStripe: 'user/isProfileStripe',
      isProfileCheckout: 'user/isProfileCheckout',
      isOwner: 'auth/isOwner',
      isStaff: 'auth/isStaff',
      isPayoutViewer: 'auth/isPayoutViewer',
      enableNymCard: 'user/enableNymCard',
      availableCardTypeList: 'payouts/availableCardTypeList',
      isProfileSelfReporting: 'user/isSelfReportingTypeProfile',
    }),
    // Для учета юзеров, кто станет стаффом в ходе онбординга
    isActualStaff() {
      return this.isStaff || (this.profile.staffType && this.profile.staffType !== NOBODY);
    },
    showOnboardingForNymUsers() {
      return this.profile.isNeedNymOnboarding;
    },
    steps() {
      let computedSteps = {
        ...GENERAL_STEPS,
      };

      if (this.isProfileStripe) {
        if (this.isOwner && !this.skipStripeOwnerStep) {
          computedSteps = {
            ...computedSteps,
            ...STRIPE_OWNER_STEPS,
          };
        }

        if (this.isActualStaff) {
          computedSteps = {
            ...computedSteps,
            ...STRIPE_STAFF_STEPS,
          };
        }

        // один из второочередных шагов должен уйти на 3 место в случае овнер+стафф
        Object.keys(computedSteps).forEach((key, index) => {
          computedSteps[key] = index + 1;
        });
      } else if (this.showOnboardingForNymUsers) {
        if (this.isActualStaff) {
          computedSteps = {
            ...computedSteps,
            ...NYM_STAFF_STEPS,
          };

          if (this.isPersonal) {
            computedSteps = {
              ...computedSteps,
              ...NYM_PERSONAL_STEPS,
            };
          }
        }
      } else if (this.isProfileCheckout) {
        if (this.isPayoutViewer && this.availableCardTypeList.length) {
          computedSteps = {
            ...computedSteps,
            ...CHECKOUT_PAYMENT_STEPS,
          };

          if (this.isPersonal && this.isActualStaff && this.isProfileSelfReporting) {
            computedSteps = {
              ...computedSteps,
              ...CHECKOUT_PERSONAL_STEPS,
            };
          }
        }
      }

      return computedSteps;
    },
    stepsCount() {
      return Object.keys(this.steps).length;
    },
    // Checking the possibility of moving to the next step using the arrows in the header
    completedSteps() {
      if (this.isProfileStripe) {
        return {
          [GENERAL_STEPS.MAIN_INFORMATION]: this.isProfileFilled,
        };
      }
      if (this.showOnboardingForNymUsers) {
        return {
          [GENERAL_STEPS.MAIN_INFORMATION]: false,
          [NYM_STAFF_STEPS.ONFIDO_VERIFICATION]: false,
          [NYM_STAFF_STEPS.PERSONAL_INFORMATION]: true,
          [NYM_STAFF_STEPS.EMPLOYER_INFORMATION]: false,
          [NYM_STAFF_STEPS.PAYOUT_CARD]: false,
        };
      }
      return {
        [GENERAL_STEPS.MAIN_INFORMATION]: this.isProfileFilled,
        [CHECKOUT_PAYMENT_STEPS.PAYOUT_CARD]: this.currentCard,
      };
    },
    stepperCurrentStep() {
      if (this.showOnboardingForNymUsers) {
        return NYM_STEPS_FOR_SHOW_IN_STEPPER[this.currentStep];
      }

      return this.currentStep;
    },
    stepperStepsCount() {
      if (this.showOnboardingForNymUsers) {
        return Math.max(...Object.values(NYM_STEPS_FOR_SHOW_IN_STEPPER));
      }

      return this.stepsCount;
    },
  },

  methods: {
    onboardingErrorHandler(e) {
      this.$showServerError(e);

      Sentry.captureException(e, {
        tags: {
          section: 'onboarding',
        },
      });
    },
    async checkNymProblemSolution() {
      try {
        const { data } = await api.nymCard.getUserStatusFromNym({ userId: this.profile.id });
        if (data.status === NYM_STATUSES.PENDINGKYC) {
          try {
            await api.nymCard.nymCardRunKyc({
              userId: this.profile.id,
            });
            this.currentStep = this.steps[this.onfidoStatusConfig.backPage];
            this.onfidoStatusConfig = {};
          } catch (e) {
            this.onboardingErrorHandler(e);
          }
        } else if (data.code) {
          this.openOnfidoErrorHandler(data);
        } else {
          this.onfidoStatusConfig = {};
          this.nextStep();
        }
      } catch (e) {
        this.onboardingErrorHandler(e);
      } finally {
        // Временное решение для создания зедержки между открытием-закрытием боттом-шита
        // в версии либы для Vue2 (в новом приложении будет НЕ актуально, так как боттом
        // шиты там синхронные), чтобы закрытие сработало после полного открытия шторки,
        // nextTick не отлавливает в нужный момент
        setTimeout(() => {
          this.cancelProfileVerificationLoading();
        }, 1000);
      }
    },
    nextStep() {
      if (this.currentStep < this.stepsCount) {
        this.currentStep += 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.cancelOnboarding();
      }
    },
    previousStep() {
      this.currentStep -= 1;
    },
    cancelOnboarding() {
      this.$router.push({ name: 'onboarding-completed' });
    },
    openOnfidoErrorHandler(statusConfig) {
      this.onfidoStatusConfig = statusConfig;
      this.$nextTick(() => {
        this.$refs.vbsOnfidoError.open();
      });
    },
    async tryAgainHandler() {
      this.$refs.vbsOnfidoError.close();
      this.currentStep = GENERAL_STEPS.MAIN_INFORMATION;
    },
    async verifyOnfidoStep() {
      try {
        const { data } = await api.nymCard.getUserStatusFromNym({ userId: this.profile.id });
        if (data.status === NYM_STATUSES.TRY_ESHE_RAZOK) {
          await this.tryAgainHandler();
        } else {
          await api.nymCard.runOnfidoCheckProcess({ userId: this.profile.id });
          const { data: statusConfig } = await api.nymCard.getUserStatusFromNym({ userId: this.profile.id });
          if (statusConfig.code) {
            this.openOnfidoErrorHandler(statusConfig);
          } else {
            this.nextStep();
          }
        }
      } catch (e) {
        this.onboardingErrorHandler(e);
      }
    },
    async profileVerificationPolling() {
      try {
        const { data } = await api.nymCard.getNymCardRegistrationToken({
          userId: this.profile.id,
          sessionId: this.nymCardSessionId,
        });
        this.nymCardToken = data.token;
        this.cancelProfileVerificationLoading();
        this.nextStep();
      } catch (e) {
        this.pollingCounter -= 1;
        if (this.pollingCounter > 0) {
          setTimeout(this.profileVerificationPolling, 1000);
        } else {
          this.cancelProfileVerificationLoading();

          this.onboardingErrorHandler(e);
        }
      }
    },
    async profileMainInformationFilledHandler() {
      if (this.showOnboardingForNymUsers && this.profile.staffType !== NOBODY) {
        this.$refs.profileVerificationLoader.open();
        try {
          const { data } = await api.nymCard.nymCardRegistration({
            userId: this.profile.id,
            firstName: this.profile.firstName,
            lastName: this.profile.lastName,
            email: this.profile.email,
            dob: this.profile.dateOfBirth,
            gender: this.profile.gender,
          });
          if (this.onfidoStatusConfig.backPage) {
            await this.checkNymProblemSolution();
          } else {
            this.nymCardSessionId = data.sessionId;
            await this.profileVerificationPolling();
          }
        } catch (e) {
          this.cancelProfileVerificationLoading();

          this.onboardingErrorHandler(e);
        }
      } else {
        this.nextStep();
      }
    },
    cancelProfileVerificationLoading() {
      this.$refs.profileVerificationLoader.close();
      this.pollingCounter = INITIAL_POLLING_COUNT;
    },
    async checkStripeOwnerStep() {
      this.hideStepper = true;

      try {
        const { data } = await api.stripe.venue.getAccount(this.location.id);

        // Если в рамках онбординга venue аккаунт уже был добавлен другим овнером, то прпоускаем шаг
        if (data?.payments) {
          this.skipStripeOwnerStep = true;
        }
      } catch (e) {
        console.log(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      } finally {
        this.hideStepper = false;
      }
    },
    onfidoErrorAlertClosedHandler() {
      if (!this.onfidoStatusConfig.backPage) {
        this.onfidoStatusConfig = {};
      }
    },
  },

  created() {
    if (this.isOwner && this.isProfileStripe) {
      this.checkStripeOwnerStep();
    }

    if (this.showOnboardingForNymUsers) {
      this.showChecklist = true;
    }

    if (this.step) {
      this.currentStep = this.step;
    }
  },
};
</script>
