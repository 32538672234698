/**
 * Install plugin.
 *
 * @param  {Vue} Vue
 */
function install(Vue) {
  /**
   * Scroll to specified mounted element.
   *
   * @param {string|HTMLElement} element
   * @param {Object|boolean} options
   * @param {HTMLElement} parent
   */
  Vue.prototype.$scroll = function $scroll(element, options = { behavior: 'smooth' }, parent = null) {
    if (!element) {
      element = this.$el;
    }

    if (!(element instanceof HTMLElement)) {
      if (!parent) {
        parent = this.$el;
      }

      // If raise method when component not still mounted.
      if (!(parent && parent.querySelector)) {
        return;
      }

      element = parent.querySelector(element);
    }

    if (element) {
      this.$nextTick(() => {
        element.scrollIntoView(options);
      });
    }
  };
}

export default install;
