import { startCase } from 'lodash';

/**
 * Build full user name.
 *
 * @param  {string} firstName
 * @param  {string} lastName
 * @return {string}
 */
export default ({ firstName, lastName }) => {
  return startCase([
    firstName,
    lastName,
  ].filter((v) => v).join(' '));
};
