import amplitude from 'amplitude-js';

class AmplitudePlugin {
  /**
   * Constructor.
   *
   * @param {string} apiKey
   */
  constructor(apiKey) {
    this.instance = amplitude.getInstance();

    if (apiKey) {
      this.instance.init(apiKey);
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  /**
   * Call amplitude event.
   *
   * @param  {...any} args
   */
  event(...args) {
    if (!this.enabled) {
      return;
    }

    this.instance.logEvent(...args);
  }

  /**
   * Install as vuejs plugin.
   *
   * @param {Vue} Vue
   */
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$amplitude', {
      get: () => {
        return this;
      },
    });
  }
}

export default new AmplitudePlugin(process.env.VUE_APP_AMPLITUDE_KEY);
