import { DateTime } from 'luxon';
import api from '@/api';
import factory from '../factory';

const POLLING_DELAY = 10e3;
const ATTR_LAST_SEEN = 'last_seen_id';
const ATTR_CONVERSATION_ID = 'conversation_id';
const ATTR_STAFF_LOGIN = 'staff_login';
const ATTR_LOCATION_ID = 'location_id';
const ATTR_JOB_TITLE = 'job_title';
const ATTR_LOCATIONS = 'locations';

const getCurrentDate = () => {
  return Math.round(DateTime.now().toMillis() / 1000);
};

export default factory({

  namespaced: true,

  state: () => ({
    admins: [],
    user: null,
    conversation: null,
    messages: [],
    handler: null,
    request: null,
  }),

  getters: {
    userInfo(state, getters, rootState) {
      const {
        id: external_id,
        email,
        firstName,
        lastName,
        phoneNumber,
        staffType,
        login,
      } = rootState.user.profile;

      return {
        external_id,
        email,
        name: `${firstName} ${lastName}`,
        phone: phoneNumber,
        location_id: rootState.user.location.id,
        job_title: staffType,
        staff_login: firstName && lastName ? null : login,
      };
    },
    admin(state, getters, rootState, rootGetters) {
      /**
       * id: "5136909"
       * name: "Daniel Steinbrück"
       * email: "dsteinbrueck@easytip.net"
       * location: Germany (for UAE)
       */
      if (rootGetters['user/isUAE']) {
        return state.admins?.[2];
      }

      /**
       * id: "5136909"
       * name: "Daniel Steinbrück"
       * email: "dsteinbrueck@easytip.net"
       * location: Germany (for Switzerland)
       */
      if (rootGetters['user/isSwitzerland']) {
        return state.admins?.[2];
      }

      /**
       * id: "5136909"
       * name: "Daniel Steinbrück"
       * email: "dsteinbrueck@easytip.net"
       * location: Germany
       */
      if (rootGetters['user/isGermany']) {
        return state.admins?.[2];
      }

      /**
       * id: "5136984"
       * name: "James Bevan"
       * email: "jbevan@easytip.net"
       * location: United Kingdom
       */
      if (rootGetters['user/isUk']) {
        return state.admins?.[3];
      }

      /**
       * id: "4989023"
       * name: "EasyTip - easytip.net"
       * email: "rzlotko@easytip.net"
       * location: All
       */
      return state.admins?.[0];
    },
    rootAdmin(state) {
      return state.admins?.[0];
    },
    newMessagesCount(state) {
      return state.messages.filter(({ read }) => !read).length;
    },
    latestMessage(state) {
      return state.messages.slice(-1).pop();
    },
    locations(state, getters, rootState) {
      return rootState.user.locations.map(({ id }) => id).join(', ');
    },
  },

  mutations: {
    setUser(state, user) {
      const latest = parseInt(user.custom_attributes?.[ATTR_LAST_SEEN], 10);

      if (state.messages) {
        state.messages.forEach((item) => {
          item.read = latest >= parseInt(item.id, 10);
        });
      }

      state.user = user;
    },
    setMessages(state, messages) {
      if (state.messages.length) {
        const currents = state.messages.map(({ id }) => id);

        messages = messages.filter(({ id }) => {
          return !currents.includes(id);
        });
      }

      const latest = parseInt(state.user.custom_attributes?.[ATTR_LAST_SEEN], 10);

      messages = messages.map((item) => {
        const datetime = DateTime.fromJSDate(new Date(item.created_at * 1000));
        const date = datetime.toFormat('dd.LL.yyyy');
        const time = datetime.toFormat('HH:mm');
        const isUser = item.author.type === 'user';
        const read = isUser ? true : latest >= parseInt(item.id, 10);

        return {
          ...item,
          datetime,
          date,
          time,
          isUser,
          read,
        };
      });

      messages = [
        ...state.messages,
        ...messages,
      ];

      messages.sort((a, b) => a.id - b.id);

      state.messages = messages;
    },
  },

  actions: {
    /**
     * Main initialize function.
     */
    async initialize({
      state,
      commit,
      dispatch,
      rootGetters,
    }) {
      if (rootGetters['auth/isAdminOrSales']) {
        return;
      }

      // Stop previous polling.
      if (state.handler) {
        dispatch('stopPolling');
        commit('reset');
      }

      // Load owner.
      await dispatch('loadAdmins');

      // Create custom attributes.
      await dispatch('createCustomAttributes');

      // Find or create user.
      await dispatch('loadUser');

      // Load latest conversation.
      await dispatch('loadConversation');
    },
    /**
     * Load information.
     */
    async loadAdmins({ commit }) {
      const {
        data: {
          admins,
        },
      } = await api.support.getAdmins();

      commit('set', { admins });
    },
    async createCustomAttributes() {
      const {
        data: {
          data,
        },
      } = await api.support.getAttributes({ model: 'contact' });

      const hasConversationAttribute = data.some(({ name }) => name === ATTR_CONVERSATION_ID);
      const hasLastSeenAttribute = data.some(({ name }) => name === ATTR_LAST_SEEN);
      const hasStaffLoginAttribute = data.some(({ name }) => name === ATTR_STAFF_LOGIN);
      const hasLocationIdAttribute = data.some(({ name }) => name === ATTR_LOCATION_ID);
      const hasJobTitle = data.some(({ name }) => name === ATTR_JOB_TITLE);
      const hasLocations = data.some(({ name }) => name === ATTR_LOCATIONS);

      if (!hasConversationAttribute) {
        await api.support.createAttribute({
          name: ATTR_CONVERSATION_ID,
          model: 'contact',
          data_type: 'string',
        });
      }

      if (!hasLastSeenAttribute) {
        await api.support.createAttribute({
          name: ATTR_LAST_SEEN,
          model: 'contact',
          data_type: 'string',
        });
      }

      if (!hasStaffLoginAttribute) {
        await api.support.createAttribute({
          name: ATTR_STAFF_LOGIN,
          model: 'contact',
          data_type: 'string',
        });
      }

      if (!hasLocationIdAttribute) {
        await api.support.createAttribute({
          name: ATTR_LOCATION_ID,
          model: 'contact',
          data_type: 'string',
        });
      }

      if (!hasJobTitle) {
        await api.support.createAttribute({
          name: ATTR_JOB_TITLE,
          model: 'contact',
          data_type: 'string',
        });
      }

      if (!hasLocations) {
        await api.support.createAttribute({
          name: ATTR_LOCATIONS,
          model: 'contact',
          data_type: 'string',
        });
      }
    },
    async loadUser({ state, getters, commit }, payload) {
      const { extraFields = null } = {
        extraFields: null,
        ...payload || null,
      };

      const {
        location_id,
        staff_login,
        job_title,
        ...userInfo
      } = getters.userInfo;

      Object.assign(userInfo, {
        owner_id: getters.admin?.id,
      });

      const {
        data: {
          data: users,
        },
      } = await api.support.searchUser({ external_id: userInfo.external_id });

      if (users.length > 0 || extraFields) {
        let optionalField = {};

        if (extraFields) {
          optionalField = extraFields;
        } else {
          optionalField = {
            updated_at: getCurrentDate(),
          };
        }

        const { data } = await api.support.updateUser({
          userId: users[0].id,
          ...userInfo,
          ...optionalField,
          custom_attributes: {
            [ATTR_LOCATION_ID]: location_id,
            [ATTR_STAFF_LOGIN]: staff_login,
            [ATTR_JOB_TITLE]: job_title,
            [ATTR_LOCATIONS]: getters.locations,
          },
        });

        commit('setUser', data);

        return;
      }

      // Temporary for search archive user.
      try {
        const { data } = await api.support.createUser({
          ...userInfo,
          created_at: getCurrentDate(),
          signed_up_at: getCurrentDate(),
          custom_attributes: {
            [ATTR_CONVERSATION_ID]: 0,
            [ATTR_LAST_SEEN]: 0,
            [ATTR_LOCATION_ID]: location_id,
            [ATTR_STAFF_LOGIN]: staff_login,
            [ATTR_JOB_TITLE]: job_title,
            [ATTR_LOCATIONS]: getters.locations,
          },
        });

        commit('setUser', data);
      } catch (e) {
        if (
          e.response?.status === 409
          && e.response.data.errors[0].code === 'conflict'
        ) {
          const userId = e.response.data.errors[0].message.replace(/^.*id=(.*)$/, '$1');
          await api.support.unarchiveUser({ userId });
          const { data } = await api.support.getUser({ userId });

          commit('setUser', data);
        }
      }
    },
    /**
     * Messages and conversations.
     */
    async loadConversation({
      state,
      commit,
      getters,
      dispatch,
    }) {
      let conversationId = parseInt(state.user.custom_attributes[ATTR_CONVERSATION_ID], 10);

      if (!conversationId) {
        const userId = state.user.id;
        const {
          data: {
            conversations,
          },
        } = await api.support.searchLastConversation({ userId });

        if (conversations.length > 0) {
          conversationId = parseInt(conversations[0].id, 10);

          const { data } = await api.support.updateUser({
            userId,
            custom_attributes: {
              [ATTR_CONVERSATION_ID]: conversationId,
            },
          });

          commit('setUser', data);
        } else {
          return;
        }
      }

      const {
        data: {
          conversation_parts: {
            conversation_parts: messages,
          },
          ...conversation
        },
      } = await api.support.getConversation({ conversationId });

      if (conversation.admin_assignee_id !== Number(getters.admin.id)) {
        await dispatch('assignConversation', {
          conversationId: conversation.id,
        });
      }

      const fullMessages = [
        {
          ...conversation.source,
          ...conversation.first_contact_reply,
        },
        ...messages,
      ];

      commit('setMessages', fullMessages);
      commit('set', { conversation });

      dispatch('startPolling');
    },
    async createConversation({
      state,
      commit,
      getters,
      dispatch,
    }, { message }) {
      const userId = state.user.id;
      const {
        data: {
          conversation_id,
        },
      } = await api.support.createConversation({ userId, message });

      await dispatch('assignConversation', {
        conversationId: conversation_id,
      });

      const { data } = await api.support.updateUser({
        userId,
        last_seen_at: getCurrentDate(),
        last_replied_at: getCurrentDate(),
        custom_attributes: {
          [ATTR_CONVERSATION_ID]: conversation_id,
          [ATTR_LOCATION_ID]: getters.userInfo.location_id,
          [ATTR_STAFF_LOGIN]: getters.userInfo.staff_login,
          [ATTR_JOB_TITLE]: getters.userInfo.job_title,
          [ATTR_LOCATIONS]: getters.locations,
        },
      });

      commit('setUser', data);

      return dispatch('loadConversation');
    },
    assignConversation({ getters }, { conversationId }) {
      return api.support.assignConversation({
        conversationId,
        adminId: getters.rootAdmin.id,
        assigneeId: getters.admin.id,
      });
    },
    async createMessage({ state, dispatch }, { message }) {
      if (!state.conversation) {
        return dispatch('createConversation', { message });
      }

      await api.support.createMessage({
        userId: state.user.id,
        conversationId: state.conversation.id,
        message,
      });

      await dispatch('loadUser', {
        extraFields: {
          last_seen_at: getCurrentDate(),
          last_replied_at: getCurrentDate(),
        },
      });

      return dispatch('updateMessages');
    },
    async updateMessages({ state, commit, dispatch }) {
      dispatch('stopPolling');

      if (state.conversation) {
        const {
          data: {
            conversation_parts: {
              conversation_parts: messages,
            },
          },
        } = await api.support.getConversation({ conversationId: state.conversation.id });

        commit('setMessages', messages);
        dispatch('startPolling');
      }
    },
    async readMessages({ state, getters, commit }) {
      if (!getters.newMessagesCount) {
        return;
      }

      const { data } = await api.support.updateUser({
        userId: state.user.id,
        custom_attributes: {
          [ATTR_LAST_SEEN]: getters.latestMessage.id,
        },
      });

      commit('setUser', data);
    },
    /**
     * Polling.
     */
    startPolling({ dispatch }) {
      dispatch('performPolling', () => {
        dispatch('updateMessages');
      });
    },
    performPolling({ commit }, fn) {
      const handler = setTimeout(fn, POLLING_DELAY);

      commit('set', {
        handler,
      });
    },
    stopPolling({ state, commit }) {
      if (state.handler) {
        clearTimeout(state.handler);
      }

      if (state.request) {
        state.request.cancel();
      }

      commit('set', {
        handler: null,
        request: null,
      });
    },
  },
});
