import { each } from 'lodash';
import UiModalAlert from '@/components/UI/ModalAlert.vue';

class AlertPlugin {
  /**
   * Alert plugin construct.
   *
   * @params {Object} Vue
   */
  constructor(Vue) {
    this.Vue = Vue;
    this.$root = null;

    this.onClosed = null;
    this.onApply = null;
    this.onCancel = null;
  }

  /**
   * Mount UI component if it needed.
   */
  mountIfNotMounted() {
    if (this.$root) {
      return;
    }

    const Component = this.Vue.extend(UiModalAlert);
    const container = document.createElement('div');

    document.getElementById('app').appendChild(container);

    this.$root = (new Component()).$mount(container);

    this.registerListeners({
      closed: 'onClosed',
      apply: 'onApply',
      cancel: 'onCancel',
    });
  }

  /**
   * Register events listeners.
   *
   * @param {Object} events Events mapping.
   */
  registerListeners(events) {
    each(events, (fn, event) => {
      this.$root.$on(event, () => {
        if (typeof this[fn] === 'function') {
          this[fn].call(this);
        }
      });
    });
  }

  /**
   * Show modal alert.
   *
   * @async
   * @param {Object} options component props data.
   * @returns {Promise}
   */
  show(options) {
    this.mountIfNotMounted();

    each(options, (value, key) => {
      this.$root[key] = value;
    });

    // Clear previous listeners.
    this.onClosed = null;
    this.onApply = null;
    this.onCancel = null;

    this.$root.$children[0].open();
  }
}

export default (Vue) => {
  const plugin = new AlertPlugin(Vue);

  /**
   * Simple modal alert.
   *
   * @async
   * @param {Object} options component props data.
   * @returns {Promise}
   */
  Vue.prototype.$alert = async (options) => {
    plugin.show({
      ...options,
      noClose: true,
    });

    return new Promise((resolve) => {
      plugin.onClosed = () => {
        resolve(true);
      };
    });
  };

  /**
   * Confirmation modal alert.
   *
   * @async
   * @param {Object} options component props data.
   * @returns {Promise}
   */
  Vue.prototype.$confirm = (options) => {
    plugin.show({
      ...options,
      noClose: false,
    });

    return new Promise((resolve) => {
      plugin.onApply = () => {
        resolve(true);
      };

      plugin.onCancel = () => {
        resolve(false);
      };
    });
  };

  /**
   * Show warning message.
   *
   * @async
   * @param {Object} options component props data.
   * @returns {Promise}
   */
  Vue.prototype.$warning = ({ message, ...options }) => {
    return Vue.prototype.$alert({
      message: `<h4>${message}</h4>`,
      ...options,
    });
  };
};
