import { DateTime } from 'luxon';
import api from '@/api';
import filterDate from '@/filters/date';
import filterTime from '@/filters/time';
import timeZone from '@/config/timeZone';

import factory from '../factory';

const POLLING_DELAY = 60e3;
const DATE_FROM = DateTime.now().minus({ days: 14 }).toISODate();
const DATE_TO = DateTime.now().toISODate();
const INCLUDE_READ = true;

export default factory({

  namespaced: true,

  state() {
    return {
      handler: null,
      loading: false,
      messages: [],
      unreadMessagesCount: 0,
      total: 0,
      dateFrom: DATE_FROM,
      dateTo: DATE_TO,
      includeRead: INCLUDE_READ,
    };
  },

  getters: {
    newMessagesCount(state, getters, rootState, rootGetters) {
      if (!rootGetters['auth/isStaff']) {
        return null;
      }

      return state.unreadMessagesCount;
    },
  },

  mutations: {
    setMessages(state, payload) {
      const filteredData = payload.data.map((item) => {
        const { message, ...itemData } = item;

        return {
          ...itemData,
          description: message,
          date: filterDate(item.sendDate, 'dd MMM yyyy'),
          time: filterTime(item.sendDate, timeZone[payload.country]),
        };
      });

      if (payload.append) {
        state.messages.push(...filteredData);
      } else {
        state.messages = filteredData;
      }
    },
    markMessage(state, payload) {
      state.messages.forEach((message) => {
        if (message.id === payload) {
          message.isRead = true;
        }
      });
    },
  },

  actions: {
    /**
     * Messages.
     */
    async loadMessages({
      state,
      commit,
      dispatch,
      rootGetters,
      rootState,
    }, payload) {
      if (rootGetters['auth/isAdminOrSales']
        || rootGetters['auth/isAccountant']
        || state.loading
      ) {
        return;
      }

      // Stop previous polling.
      if (state.handler) {
        dispatch('stopPolling');
      }

      const {
        start = 0,
        pageSize = 5,
        includeRead = null,
        dateFrom = null,
        dateTo = null,
        append = false,
      } = {
        ...payload || null,
      };

      let tempState = {};

      if (dateFrom && dateTo) {
        tempState = {
          dateFrom,
          dateTo,
        };
      }

      if (includeRead !== null) {
        tempState = {
          ...tempState,
          includeRead,
        };
      }

      commit('set', {
        ...tempState,
        loading: true,
      });

      try {
        const { data: notificationsData } = await api.notifications.getMessages({
          start,
          pageSize,
          includeRead: state.includeRead,
          dateFrom: state.dateFrom,
          dateTo: state.dateTo,
          userId: rootState.user.profile.id,
        });

        if (notificationsData.data.length) {
          commit('setMessages', {
            data: notificationsData.data,
            country: rootState.user.location.country,
            append,
          });

          commit('set', {
            total: notificationsData.totalRecords,
          });

          await dispatch('loadUnreadMessagesCount');

          dispatch('startPolling');
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', { loading: false });
      }
    },
    async markMessageAsRead({ commit, dispatch, rootState }, { messageId }) {
      await api.notifications.markMessageAsRead({
        messageId,
        userId: rootState.user.profile.id,
      });

      commit('markMessage', messageId);
      dispatch('loadMessages');
    },
    async markMessagesAsRead({ dispatch, rootState }) {
      await api.notifications.markMessagesAsRead({
        userId: rootState.user.profile.id,
      });

      dispatch('loadMessages');
    },
    async loadUnreadMessagesCount({ commit, rootState }) {
      try {
        const {
          data: {
            count,
          },
        } = await api.notifications.getUnreadMessagesCount({
          userId: rootState.user.profile.id,
        });

        commit('set', { unreadMessagesCount: count });
      } catch (e) {
        throw e;
      }
    },

    /**
     * Polling.
     */
    startPolling({ dispatch }) {
      dispatch('performPolling', () => {
        dispatch('loadMessages');
      });
    },
    performPolling({ commit }, fn) {
      const handler = setTimeout(fn, POLLING_DELAY);

      commit('set', {
        handler,
      });
    },
    stopPolling({ state, commit }) {
      if (state.handler) {
        clearTimeout(state.handler);
      }

      if (state.request) {
        state.request.cancel();
      }

      commit('set', {
        handler: null,
        request: null,
      });
    },
  },
});
