import api from '@/api';
import i18n from '@/plugins/i18n';
import { UNKNOWN, NOBODY } from '@/config/staffTypes';

import factory from '../factory';

const isDateExpired = (date) => {
  return (Date.now() - date) >= process.env.VUE_APP_HANDBOOK_CACHE_TIME;
};

export default factory({

  namespaced: true,

  state: () => ({
    date: null,
    versionList: [],
    staffTypes: null,
    countries: null,
    versionLoading: false,
    staffTypesLoading: false,
    countriesLoading: false,
  }),

  getters: {
    staffTypes(state) {
      if (!state.staffTypes) {
        return [];
      }

      const MASK = {
        en: 'UK',
        de: 'DE',
      };
      const LANG = MASK[i18n.locale];

      return state.staffTypes
        .map((data) => ({
          ...data,
          name: data.name[LANG],
        }));
    },
    staffTypesVersion(state) {
      return state.versionList.find(({ name }) => name === 'STAFF_TYPE')?.version;
    },
    staffTypeName(state, getters) {
      return (staffType) => {
        if (staffType === NOBODY) {
          return 'N/A';
        }

        return getters.staffTypes
          .find(({ value }) => value === staffType)
          ?.name || UNKNOWN;
      };
    },
    filteredStaffTypes(state, getters, rootState) {
      const filtered = getters.staffTypes
        .filter((staff) => staff.types.includes(rootState.user.location.type))
        .filter((staff) => staff.countries.includes(rootState.user.location.country));

      return [
        ...filtered,
        {
          name: 'N/A',
          value: NOBODY,
          type: rootState.user.location.type,
        },
      ];
    },
    staffTypeDisabled(state, getters, rootState, rootGetters) {
      const isRestaurantOrHotel = rootState.user.location.type === 'RESTAURANT' || rootState.user.location.type === 'HOTEL';
      const isGermanyOrUAE = rootGetters['user/isGermany'] || rootGetters['user/isUAE'];

      return rootGetters['user/isMasterLocation'] || (isGermanyOrUAE && isRestaurantOrHotel);
    },
  },

  actions: {
    async loadVersion({ state, commit }) {
      if (state.versionLoading) {
        return;
      }

      commit('set', { versionLoading: true });

      try {
        const {
          data: {
            versionList,
          },
        } = await api.handbook.getVersion();

        commit('set', {
          versionList,
          date: Date.now(),
        });
      } catch (e) {
        throw e;
      } finally {
        commit('set', { versionLoading: false });
      }
    },
    async loadStaffTypes({ state, commit }) {
      if (state.staffTypesLoading) {
        return;
      }

      commit('set', { staffTypesLoading: true });

      try {
        const { data } = await api.handbook.getStaffTypes();

        const filteredStaffTypes = data
          .filter(({ status }) => status === 'ACTIVE')
          .map((type) => ({
            id: type.id,
            name: type.localeValueMap,
            value: type.name,
            types: type.availableWorkplaceTypes,
            countries: type.countryISOCodeList,
          }));

        commit('set', {
          staffTypes: filteredStaffTypes,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('set', { staffTypesLoading: false });
      }
    },
    async loadCountries({ state, commit }) {
      if (state.countriesLoading) {
        return;
      }

      commit('set', { countriesLoading: true });

      try {
        const { data } = await api.handbook.getCountries({ onlyBusinessRegion: true });

        commit('set', {
          countries: data,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('set', { countriesLoading: false });
      }
    },
    async getStaffTypes({ state, getters, dispatch }, payload) {
      const {
        force = false,
      } = { ...payload };

      if (!getters.staffTypesVersion || !state.staffTypes || force) {
        await dispatch('loadVersion');
        await dispatch('loadStaffTypes');
      } else if (isDateExpired(state.date)) {
        const version = getters.staffTypesVersion;

        await dispatch('loadVersion');

        if (version !== getters.staffTypesVersion) {
          await dispatch('loadStaffTypes');
        }
      }

      return getters.staffTypes;
    },
  },
});
