import i18n from '@/plugins/i18n';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: {
    show: false,
  },

  getters: {
    showReporting(state, getters, rootState, rootGetters) {
      return !rootGetters['auth/isAdminOrSales'];
    },
    showReviews(state, getters, rootState, rootGetters) {
      return (rootGetters['auth/isManager']
        || rootGetters['auth/isOwner']
        || rootGetters['auth/isDistributionMaster'])
        && rootGetters['user/showReviewsModule'];
    },
    showOnlineMenu(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isOwner']
        && rootGetters['user/isOnlineMenuEnabled'];
    },
    showDistributions(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isDistributionMaster'] || rootGetters['auth/isAccountant'];
    },
    showDistributionsManagerAndAccountant(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isDistributionMaster'] && rootGetters['auth/isAccountant'];
    },
    showAlerts(state, getters, rootState, rootGetters) {
      if (rootGetters['user/isUAE']) {
        return false;
      }

      return rootGetters['auth/isAccountant']
        || rootGetters['auth/isOwnerCommon']
        || rootGetters['auth/isDistributionMaster'];
    },
    showSupport(state, getters, rootState, rootGetters) {
      return !rootGetters['auth/isAdminOrSales'];
    },
    showTransactions(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isAdmin']
        || rootGetters['auth/isTransationsManager'];
    },
    showPayouts(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isPayoutViewer'];
    },
    showOverview(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isSales'];
    },
    showLocations(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isAdminOrSales'];
    },
    showTerms(state, getters, rootState, rootGetters) {
      return !rootGetters['auth/isAdminOrSales'];
    },
    showPrivacy(state, getters, rootState, rootGetters) {
      return !rootGetters['auth/isAdminOrSales'];
    },
    showPromotions(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isPromoMaster'];
    },
    showNotifications(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isAdmin']
        || rootGetters['auth/isNotificationsManager'];
    },
    showRegisteredUsers(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isAdminOrSales'];
    },
    access(state, getters) {
      return {
        reporting: getters.showReporting,
        reviews: getters.showReviews,
        onlineMenu: getters.showOnlineMenu,
        distributions: getters.showDistributions,
        distributionsManagerAndAccountant: getters.showDistributionsManagerAndAccountant,
        alerts: getters.showAlerts,
        support: getters.showSupport,
        transactions: getters.showTransactions,
        payouts: getters.showPayouts,
        overview: getters.showOverview,
        locations: getters.showLocations,
        terms: getters.showTerms,
        privacy: getters.showPrivacy,
        promotions: getters.showPromotions,
        notifications: getters.showNotifications,
        registeredUsers: getters.showRegisteredUsers,
      };
    },
    links(state, getters, rootState, rootGetters) {
      return [
        {
          name: i18n.t('reporting.title'),
          icon: 'reporting',
          visible: getters.access.reporting,
          to: {
            name: 'reporting',
          },
        },
        {
          name: i18n.t('payouts.title'),
          icon: 'payout',
          visible: getters.access.payouts,
          to: {
            name: 'payouts',
          },
        },
        {
          name: i18n.t('reviews.title'),
          icon: 'reviews',
          visible: getters.access.reviews,
          to: {
            name: 'reviews',
          },
        },
        {
          name: i18n.t('onlineMenu.title'),
          icon: 'online-menu',
          visible: getters.access.onlineMenu,
          to: {
            name: 'online-menu',
          },
        },
        {
          name: i18n.t('distributions.title'),
          icon: 'money',
          visible: getters.access.distributions && !getters.access.distributionsManagerAndAccountant,
          to: {
            name: 'distributions',
          },
        },
        {
          name: 'Distributions manager',
          icon: 'money',
          visible: getters.access.distributionsManagerAndAccountant,
          to: {
            name: 'distributions-manager',
          },
        },
        {
          name: 'Distributions account',
          icon: 'money',
          visible: getters.access.distributionsManagerAndAccountant,
          to: {
            name: 'distributions-account',
          },
        },
        {
          name: i18n.t('alerts.title'),
          icon: 'alerts',
          visible: getters.access.alerts,
          to: {
            name: 'alerts',
          },
        },
        {
          name: i18n.t('support.title'),
          icon: 'support',
          visible: getters.access.support,
          badge: rootGetters['support/newMessagesCount'] || rootGetters['notifications/newMessagesCount'] ? {
            value: rootGetters['support/newMessagesCount'] + rootGetters['notifications/newMessagesCount'],
            type: 'primary',
          } : undefined,
          to: {
            name: 'support',
          },
        },
        {
          name: i18n.t('transactions.title'),
          icon: 'payin',
          visible: getters.access.transactions,
          to: {
            name: 'transactions',
          },
        },
        {
          name: 'overview',
          icon: 'overview',
          visible: getters.access.overview,
          to: {
            name: 'overview',
          },
        },
        {
          name: i18n.t('locations.title'),
          icon: 'location',
          visible: getters.access.locations,
          to: {
            name: 'locations',
          },
        },
        {
          name: i18n.t('promotions.title'),
          icon: 'promotions',
          visible: getters.access.promotions,
          to: {
            name: 'promotions',
          },
        },
        {
          name: i18n.t('notifications.title'),
          icon: 'notifications',
          visible: getters.access.notifications,
          to: {
            name: 'notifications',
          },
        },
        {
          name: i18n.t('terms.title'),
          icon: 'terms',
          visible: getters.access.terms,
          to: {
            name: 'terms',
          },
        },
        {
          name: i18n.t('privacy.title'),
          icon: 'privacy',
          visible: getters.access.privacy,
          to: {
            name: 'privacy-policy',
          },
        },
        {
          name: i18n.t('registeredUsers.title'),
          icon: 'user',
          visible: getters.access.registeredUsers,
          to: {
            name: 'registered-users',
          },
        },
        {
          name: i18n.t('logout.title'),
          icon: 'logout',
          visible: true,
          to: {
            name: 'logout',
          },
        },
      ].filter(({ visible }) => visible);
    },
  },

  mutations: {
    open(state) {
      state.show = true;
    },
    hide(state) {
      state.show = false;
    },
    toggle(state) {
      state.show = !state.show;
    },
  },
});
