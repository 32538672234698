import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

import api from '@/api';

import createSentry from './plugins/sentry';

import auth from './modules/auth';
import user from './modules/user';
import sidebar from './modules/sidebar';
import staffs from './modules/staffs';
import i18n from './modules/i18n';
import support from './modules/support';
import groups from './modules/groups';
import settings from './modules/settings';
import handbook from './modules/handbook';
import payouts from './modules/payouts';
import distributions from './modules/distributions';
import notifications from './modules/notifications';
import menu from './modules/menu';
import sublocations from './modules/sublocations';

Vue.use(Vuex);

const { NODE_ENV } = process.env;

export default new Vuex.Store({

  strict: NODE_ENV !== 'production',

  getters: {
    api(state, getters) {
      if (getters['auth/isAdminOrSales']) {
        return api.admin;
      }

      return api.staff;
    },
  },

  actions: {
    /**
     * Reset store
    */
    resetStore({ commit }, mode) {
      let modules = [];

      if (mode === 'auth') {
        modules = [
          'user',
          'sidebar',
          'staffs',
          'support',
          'groups',
          'settings',
          'handbook',
          'payouts',
          'sublocations',
        ];
      }

      if (mode === 'location-change') {
        modules = [
          'sidebar',
          'staffs',
          'support',
          'groups',
          'settings',
          'handbook',
          'payouts',
          'sublocations',
        ];
      }

      if (modules.length) {
        modules.forEach((module) => {
          commit(`${module}/reset`, null, { root: true });
        });
      }
    },
  },

  modules: {
    auth,
    user,
    sidebar,
    staffs,
    i18n,
    support,
    groups,
    settings,
    handbook,
    payouts,
    distributions,
    notifications,
    menu,
    sublocations,
  },

  plugins: [
    createPersistedState({
      paths: [
        'auth.accessToken',
        'auth.refreshToken',
        'auth.expiresIn',
        'auth.userStatus',
        'auth.roles',
        'auth.viewAs',
        'handbook.versionList',
        'handbook.date',
        'handbook.staffTypes',
      ],
    }),
    createMutationsSharer({
      predicate: [
        'auth/setToken',
        'auth/removeToken',
      ],
    }),
    createSentry(),
    (store) => {
      store.subscribe((mutation) => {
        if (mutation.type === 'auth/removeToken') {
          store.commit('user/reset');
          store.commit('staffs/reset');
          store.commit('support/reset');
          store.commit('groups/reset');
          store.commit('sidebar/reset');
          store.commit('payouts/reset');
          store.commit('handbook/set', { countries: null });
        }
      });
    },
  ],
});
