/* eslint-disable class-methods-use-this, max-classes-per-file */

import {
  API_SWITCH_VAR,
  API_SWITCH_ENABLED,
  API_SWITCH_ADMIN,
  API_SWITCH_INTERCOM,
  API_SWITCH_MEDIA,
} from '@/config/api';
import api from '@/api';
import store from '@/store';
import AxiosInterceptor from '@/class/ApiSwitch/AxiosInterceptor';
import storage from './storage';

const API_SWITCH_VIEWAS_VAR = `${API_SWITCH_VAR}-viewas`;

class AdminSwitch extends AxiosInterceptor {
  /**
   * Constructor.
   *
   * @param {string} selected
   * @param {Function} onUpdate
   */
  constructor(selected, onUpdate) {
    super({
      axios: api.instance.easytip,
      items: API_SWITCH_ADMIN,
      selected,
      onUpdate,
    });
  }

  onSuccess(config) {
    super.onSuccess(config);

    if (this.isSigninRequest(config)) {
      storage.set(API_SWITCH_VIEWAS_VAR, this.selectedValue);
    }
  }

  isSigninRequest(config) {
    return this.isUrl(config, '/v1/auth/signin');
  }

  /** @inheritdoc */
  isAuthRequest(config) {
    if (this.isSigninRequest(config)) {
      return true;
    }

    if (this.isUrl(config, '/v1/public/account/restore')) {
      return true;
    }

    if (this.isUrl(config, '/v2/users/profile')) {
      return !store.getters['auth/isAdmin'];
    }

    return false;
  }

  /** @inheritdoc */
  isUserRequest(config) {
    if (store.getters['auth/isAdmin']) {
      return this.someUrl(config, [
        '/v2/users/profile',
        '/v1/admin/settings/image',
        '/v1/auth/logout',
      ]);
    }

    if (store.state.auth.viewAs && this.isUrl(config, '/v1/auth/refresh')) {
      const server = this.byBaseURL(config.baseURL);

      if (server.name === storage.get(API_SWITCH_VIEWAS_VAR)) {
        return true;
      }
    }

    return false;
  }

  /** @inheritdoc */
  isAuthError({ status, data }) {
    // User not found.
    if (status === 404 && data?.errorCode === 404002) {
      return true;
    }

    if (status === 422 && data?.errorType === 'BadAuthDataException') {
      return true;
    }

    if (status === 418) {
      return true;
    }

    return false;
  }
}

class IntercomSwitch extends AxiosInterceptor {
  constructor(selected) {
    super({
      axios: api.instance.intercom,
      items: API_SWITCH_INTERCOM,
      selected,
    });
  }
}

class MediaSwitch extends AxiosInterceptor {
  constructor(selected) {
    super({
      axios: api.instance.media,
      items: API_SWITCH_MEDIA,
      selected,
    });
  }
}

export default (Vue) => {
  if (!API_SWITCH_ENABLED) {
    return;
  }

  const selected = storage.get(API_SWITCH_VAR);

  const intercom = new IntercomSwitch(selected);
  const media = new MediaSwitch(selected);
  const admin = new AdminSwitch(selected, (value) => {
    storage.set(API_SWITCH_VAR, value);
    intercom.selected = value;
    media.selected = value;
  });

  intercom.boot();
  media.boot();
  admin.boot();

  Vue.prototype.$apiSwitch = admin;
};
