import { availableCountries } from '@/config/countries';

export default (Vue) => {
  Vue.prototype.$getCountries = function $getCountries(config) {
    config = {
      i18n: false,
      empty: false,
      ...config || {},
    };

    let results = [...availableCountries];

    if (this.$apiSwitch) {
      const { selected } = this.$apiSwitch;

      if (selected) {
        if (!this.$store.state.handbook.countries) {
          this.$store.dispatch('handbook/loadCountries');
        } else {
          results = results.filter(({ iso }) => {
            return this.$store.state.handbook.countries.some(({ isoCode }) => isoCode === iso) && selected.country.includes(iso);
          });
        }
      }
    }

    if (config.i18n) {
      results = results.map((item) => ({
        ...item,
        name: this.$t(`countries.${item.iso}`),
      }));
    }

    if (config.empty) {
      results = [
        {
          iso: undefined,
          name: 'All countries',
        },
        ...results,
      ];
    }

    return results;
  };
};
