import api from '@/api';
import getFio from '@/utils/fio';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    locationId: null,
    loaded: false,
    loading: false,
    items: [],
  }),

  actions: {
    async loadItems({ state, commit }, { locationId }) {
      if (state.loading && state.locationId === locationId) {
        return;
      }

      commit('set', {
        loading: true,
        locationId,
      });

      try {
        const { data } = await api.locations.getGroups({
          locationId,
        });

        if (data) {
          const items = data.filter((group) => group.id).map((group) => ({
            ...group,
            staffs: group.staffs.map((staff) => ({
              ...staff,
              fio: getFio(staff),
            })),
          }));

          commit('set', {
            loaded: true,
            items,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', { loading: false });
      }
    },
  },
});
