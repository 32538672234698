<template>
  <div class="wrapper">
    <app-view-as />

    <ui-sidebar @click="hideSidebar" :links="sidebarLinks">
      <template #header-additional>
        <user-api-switch v-if="showApiSwitch" />
      </template>

      <user-locations-dropdown v-if="!isAdminOrSales" />
      <router-link :to="{ name: 'profile' }" class="user" @click.native.stop="hideSidebar">
        <div class="photo">
          <img
            v-if="image"
            :src="image"
            alt="user avatar"
          />
        </div>
        <div class="info">
          <span>
            {{ fio || $t('profile.title') }}
          </span>
        </div>
      </router-link>
    </ui-sidebar>

    <div class="main-panel">
      <app-topbar></app-topbar>
      <div class="app-title app-title_primary">{{ $t($route.meta.title) }}</div>
      <app-content></app-content>
      <app-footer contented></app-footer>
    </div>

    <root-modals />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import AppViewAs from '@/components/AppViewAs.vue';
import AppTopbar from '@/components/AppTopbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppContent from '@/components/AppContent.vue';
import UserLocationsDropdown from '@/components/User/UserLocationsDropdown.vue';
import UserApiSwitch from '@/components/User/UserApiSwitch.vue';
import RootModals from '@/components/RootModals.vue';

export default {

  components: {
    RootModals,
    AppViewAs,
    AppTopbar,
    AppFooter,
    AppContent,
    UserLocationsDropdown,
    UserApiSwitch,
  },

  computed: {
    ...mapGetters({
      sidebarLinks: 'sidebar/links',
      fio: 'user/fio',
      isAdmin: 'auth/isAdmin',
      isAdminOrSales: 'auth/isAdminOrSales',
      needRequestId: 'user/needRequestId',
    }),
    ...mapState('user', [
      'image',
    ]),
    showApiSwitch() {
      return this.isAdmin && this.$apiSwitch;
    },
  },

  created() {
    this.startSupport();
  },

  beforeDestroy() {
    this.stopSupport();
  },

  methods: {
    ...mapMutations({
      hideSidebar: 'sidebar/hide',
      setVisibilityRequestIdModal: 'user/setVisibilityRequestIdModal',
    }),
    ...mapActions({
      startSupport: 'support/initialize',
      stopSupport: 'support/stopPolling',
    }),
  },

  watch: {
    '$route.name': {
      immediate: true,
      handler() {
        if (this.needRequestId) {
          this.setVisibilityRequestIdModal(true);
        }
      },
    },
  },
};
</script>
