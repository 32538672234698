/**
 * Check specified value was url.
 *
 * @param {string} value
 * @param {string} [base]
 * @return {boolean}
 */
export default (value, ...args) => {
  if (typeof value !== 'string') {
    return false;
  }

  try {
    const url = new URL(value, ...args);

    if (url) {
      return true;
    }
  } catch (e) {
    // noop
  }

  return false;
};
