import api from '@/api';
import getFio from '@/utils/fio';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    loaded: false,
    loading: false,
    items: [],
  }),

  actions: {
    async loadItems({ state, commit }, { locationId, onlyActive = true }) {
      commit('set', { loading: true });

      try {
        const { data } = await api.locations.getStaffs({
          locationId,
          isActive: onlyActive || null,
        });

        if (data) {
          const items = data.map((item) => ({
            ...item,
            fio: getFio(item),
          }));

          commit('set', {
            loaded: true,
            items,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        commit('set', { loading: false });
      }
    },
  },
});
