import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '@/router';

if (!['development', 'test'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: 'https://751b634d1c794c34a8d4eb34f33e8793@o1338000.ingest.sentry.io/6608364',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    ignoreErrors: [
      /^Redirected when going from.*/,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
